import { Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useEffect, useState } from 'react'

type ProgressBarProps = {
  isVisible?: boolean
  title: string
  progress: number
}

const TIME = 3000

const ProgressBar = ({ isVisible = true, title, progress }: ProgressBarProps) => {
  const [percent, setPercent] = useState(0)
  const { isMobile } = useMatchBreakpoints()

  useEffect(() => {
    if (progress > 0) {
      let interval = setInterval(() => {
        setPercent((prev) => prev + 1)

        if (percent >= progress) {
          clearInterval(interval)
        }
      }, (progress / TIME) * 10000)
      if (percent >= progress) {
        clearInterval(interval)
      }
      return () => clearInterval(interval)
    }
    return () => null
  }, [percent, progress])

  return (
    <div style={{ marginBottom: '20px' }}>
      <Text
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          fontWeight: '700',
          fontSize: '20px',
        }}
      >
        {title}
      </Text>
      <div
        style={{
          position: 'relative',
          background: '#2B2A44',
          borderRadius: '8px',
          height: '24px',
        }}
      >
        <div
          style={{
            width: isVisible ? `${progress}%` : 0,
            height: '100%',
            background: 'var(--Primary-Primary-Gradient-2, linear-gradient(180deg, #7B3FE4 0%, #A726C1 100%))',
            borderRadius: '8px',
            transition: `all ${TIME}ms ease-in-out`,
            position: 'relative',
          }}
        >
          <Text
            style={{
              fontWeight: 700,
              position: 'absolute',
              transition: `all ${TIME}ms ease-in-out`,
              width: '100%',
              textAlign: 'end',
              paddingRight: '10px',
            }}
          >
            {percent}%
          </Text>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar
