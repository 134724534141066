import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input } from '@pancakeswap/uikit'
import { useState, useEffect } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import { InjectedProps } from '@pancakeswap/uikit/src/widgets/Modal/types'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import styled from 'styled-components'
import Link from 'next/link'
// import { Modal } from 'antd'
import Image from 'next/image'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
interface RegistersModalProps extends InjectedProps {}

const StyledInput = styled(Input)`
  outline: none;
  border: 3px solid #009571;
  border-radius: 10px;
`

export const ModalVote = ({ onVote, onDismiss }: { onVote: () => void; onDismiss: () => void }) => {
  const { account } = useActiveWeb3React()
  var newLocal = []
  const handleCancel = () => {
    if (!localStorage.getItem('isVotedII')) {
      newLocal.push(account)
      localStorage.setItem('isVotedII', JSON.stringify(newLocal))
      onVote()
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedII'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedII', JSON.stringify(newLocal))
      onVote()
      onDismiss()
    }
  }

  const handleOk = () => {
    if (!localStorage.getItem('isVoted')) {
      newLocal.push(account)
      localStorage.setItem('isVoted', JSON.stringify(newLocal))
      onVote()
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVoted'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVoted', JSON.stringify(newLocal))
      onVote()
      onDismiss()
    }
  }
  return (
    <Modal title="Notification" onDismiss={handleCancel} style={{ width: '320px' }}>
      <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
        <Image src="/images/logo-mobile.png" alt="" width="50px" height="50px" />
      </div>
      <Grid alignItems="center" justifyContent="center" mt="20px">
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '16px', textAlign: 'center' }}>
          Important Update for POL Holders
        </h1>
        <br />
      </Grid>
      <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'center', alignItems: 'center' }}>
        <Link href="/vote">
          <Button
            style={{ background: '#8544F5', boxShadow: '2px 2px 8px 16px #0000001A', color: '#FFFFFF' }}
            height="40px"
            onClick={handleOk}
          >
            Join now!
          </Button>
        </Link>
      </div>
    </Modal>
  )
}
