import styled from 'styled-components'
import { Heading, Text, Flex, Button, useToast, Input, LinkExternal, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { getContract, getPoolsContract, getPoolsV2Contract, getPoolsV4Contract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import readTrendyAbi from 'config/abi/readTrendy.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import truncateHash from '@pancakeswap/utils/truncateHash'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import moment from 'moment'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { formatEther } from '@ethersproject/units'
import { NATIVE } from '../../../packages/swap-sdk/src/constants'
import { shortenURL } from 'views/Pools2/util'
import { getBlockExploreLink } from 'utils'
import PageReferral from './components/PageReferral'
import PageProfile from './components/PageProfile'
import PageDashboardV2 from './components/ReferralDashboardV2'
import PageDashboardV1 from './components/ReferralDashboardV1'
import TopRank from './components/TopRank'

const Wrapper = styled.div`
  * {
    font-family: Inter, sans-serif;
  }
  width: 100%;
  max-width: 1320px;
  height: auto;
  min-height: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 96px 0;
  display: flex;
  gap: 32px;
  th {
    border-collapse: collapse;
  }
  th,
  td {
    padding: 10px;
    text-align: center;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 60px 0;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    flex-direction: column;
    padding: 80px 0;
    width: 100%;
  }
`

const ButtonChangePage = styled.button`
  background: linear-gradient(
    178.36deg,
    #5c4a8a 1.4%,
    #d2cbef 1.41%,
    rgba(144, 126, 222, 0.62) 26.34%,
    #7b6fef 71.12%,
    #3c59f2 109.1%
  );
  border: none;
  outline: none;
  cursor: pointer;
`

export const copyText = (text) => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
const Menu = styled.div`
  max-width: 300px;
  width: 20%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
  }
  @media screen and (max-width: 575px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    justify-content: space-around;
  }
`
const MenuItemActive = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 8px;
padding 8px 16px;
width: 292px;
img{
  width: 24px;
  height: 24px;
}
.title {
  font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; 
color: rgba(252, 252, 253, 1);
}
@media screen and (max-width: 900px) {
  width: 40%;
}
@media screen and (max-width: 575px) {
  padding: 0 8px;
  width: 100%;
  flex-direction: column;
}
`
const MenuItemDisabled = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 8px;
padding 8px 16px;
width: 292px;

img{
  width: 24px;
  height: 24px;
}
.title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
color: rgba(103, 102, 110, 1);
}
cursor: pointer;
@media screen and (max-width: 575px) {
  padding: 0 8px;
  width: 100%;
  flex-direction: column;
}
`
const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const Referral = () => {
  const [linkRef, setLinkRef] = React.useState('')
  const [showCopied, setShowCopied] = React.useState(false)
  const { account, chainId } = useWeb3React()
  // account = '0x8124C8B1E6F444de3D4D49198A291804ddc3C57a'
  const dispatch = useDispatch()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [loadingPage, setLoadingPage] = React.useState(true)
  const [loadingTable, setLoadingTable] = React.useState(true)
  const { search } = window.location
  const query = new URLSearchParams(search)
  const referBy = query.get('ref')
  const baseRefUrl = `${window.location.origin}homepage?ref=`
  const { data: signer } = useSigner()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const getPoolContract = getPoolsContract(CHAIN_ID)
  const getPoolV2Contract = getPoolsV2Contract(CHAIN_ID)
  const getPoolV4Contract = getPoolsV4Contract(CHAIN_ID)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const readTrendyCT = getContract({
    address: addresses.readTrendy[CHAIN_ID],
    abi: readTrendyAbi,
    chainId: CHAIN_ID,
    signer,
  })
  const [userIsRegister, setUserIsRegister] = React.useState(false)
  const [interest, setInterest] = React.useState(0)
  const [listChild, setListChild] = React.useState([])
  const [listChildV1, setListChildV1] = React.useState([])
  const [topReferral, setTopReferral] = useState([])
  const [countPage, setCountPage] = React.useState(0)
  const [activePage, setActivePage] = React.useState(0)
  const [myCode, setMyCode] = useState('')
  const [referByWallet, setReferByWallet] = useState(referBy)
  const [referCode, setReferCode] = useState('')
  const [showError, setShowError] = useState(true)
  const [userSearch, setUserSearch] = useState('')
  const [resultSearch, setResultSearch] = useState('')
  const [totalItemChild, setTotalItemChild] = React.useState(0)
  const [acountChild, setAccountChild] = React.useState(() => {
    if (account) {
      return [account]
    }
    return []
  })
  const [total7Level, setTotal7Level] = React.useState(0)
  const unit = chainId && NATIVE[chainId].symbol
  const [userInfos, setUserInfo] = React.useState({
    refferBy: '',
    date: 0,
    totalReffer: '',
    totalRefer7: '',
    directStaked: 0,
    totalStaked7: 0,
    totalComms: 0,
  })
  const { isMobile } = useMatchBreakpoints()

  const [tab, setTab] = useState(2)
  const getTotalRefferChild = async (page, accountChild) => {
    if (!account) {
      setLoadingPage(true)
    }
    setLoadingPage(false)
    const limit = 5
    const data = await Promise.all([
      refferCT.getTotalUserByUp(accountChild ? accountChild : account, limit, page),
      refferCT.userInfos(account ? account : accountChild),
    ])

    const countPage = Math.ceil(Number(data[0].totalItem.toString()) / limit)
    const arr = data[0].list.map((item) => item.user)
    const dataTrendy = await readTrendyCT.volumeOntree(arr)
    const dataTrendyV1 = await readTrendyCT.volumeOntreeV1(arr)

    setListChild(
      arr.map((item, i) => {
        return {
          account: item,
          volume: Number(formatEther(dataTrendy.volumes[i])).toFixed(3),
          locked: Number(formatEther(dataTrendy.userTotalLocks[i])).toFixed(3),
          child: Number(dataTrendy.totalRefers[i].toString()),
          showChild: false,
        }
      }),
    )
    setListChildV1(
      arr.map((item, i) => {
        return {
          account: item,
          volume: Number(formatEther(dataTrendyV1.volumes[i])).toFixed(3),
          locked: Number(formatEther(dataTrendyV1.userTotalLocks[i])).toFixed(3),
          child: Number(dataTrendyV1.totalRefers[i].toString()),
          showChild: false,
        }
      }),
    )

    setTotalItemChild(Number(data[0].totalItem.toString()))
    setCountPage(countPage)
    setTotal7Level(data[1].totalRefer7.toString())
    setLoadingTable(false)
  }

  const validateReferByWallet = async (e) => {
    setReferCode(e.target.value)
    const code = e.target.value

    const userInfosByCode = await refferCT.userInfosByCode(code.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') setShowError(true)
    else {
      setShowError(false)
      setReferByWallet(userInfosByCode.user)
    }
  }

  const handleClickPage = (index) => {
    const limit = 5
    const skip = index * limit
    getTotalRefferChild(skip, acountChild[acountChild.length - 1])
    setActivePage(index)
  }

  const handleChangeChild = (accountB) => {
    setListChild(
      listChild.map((l) => {
        if (l.account.toLowerCase() === accountB.toLowerCase()) l.showChild = !l.showChild
        return l
      }),
    )
  }

  const handleChangePage = (index) => {
    if (typeof Number(index) !== 'number') {
      return
    }
    const limit = 5
    const skip = index * limit
    getTotalRefferChild(skip, acountChild[acountChild.length - 1])
    setActivePage(Number(index))
  }

  const getUserInfo = async () => {
    if (!account) {
      setLoadingPage(true)
    }
    setLoadingPage(false)
    const infos = await Promise.all([
      refferCT.userInfos(account),
      getPoolV4Contract.getChildren(account),
      getPoolV2Contract.getChildren(account),
      getPoolContract.volumeOntree(account),
      getPoolContract.remainComm(account),
      getPoolV2Contract.remainComm(account),
      getPoolV4Contract.remainComm(account),
    ])

    const user = {
      refferBy: infos[0].refferBy.toString(),
      date: Number(infos[0].dateTime.toString()) * 1000,
      totalReffer: infos[0].totalRefer.toString(),
      totalRefer7: infos[0].totalRefer7.toString(),
      directStaked: Number(infos[1].direct.toString()) + Number(infos[2].direct.toString()),
      totalStaked7: Number(Number(formatEther(infos[3])).toFixed(3)),
      totalComms: Number(Number(formatEther(infos[4])) + Number(formatEther(infos[5])) + Number(formatEther(infos[6]))),
    }
    setUserInfo(user)
  }
  // console.log(userInfos.totalComms);

  const getButtonChangePage = (limitButton) => {
    let arr = []
    const style = { background: '#00f0e1', color: 'black' }
    if (countPage === 1) {
      return null
    }
    if (countPage >= 4) {
      for (let i = 0; i < limitButton; i++) {
        arr.push(
          <ButtonChangePage key={i} onClick={() => handleClickPage(i)} style={activePage === i ? style : {}}>
            {i + 1}
          </ButtonChangePage>,
        )
      }
      arr.push(
        <input key={'a'} type="number" style={{ width: '40px' }} onChange={(e) => handleChangePage(e.target.value)} />,
      )
      arr.push(
        <ButtonChangePage
          key={countPage}
          style={activePage === countPage - 1 ? style : {}}
          onClick={() => handleClickPage(countPage - 1)}
        >
          {countPage - 1}
        </ButtonChangePage>,
      )
    } else {
      for (let i = 0; i < countPage; i++) {
        arr.push(
          <ButtonChangePage key={i} style={activePage === i ? style : {}} onClick={() => handleClickPage(i)}>
            {i + 1}
          </ButtonChangePage>,
        )
      }
    }
    return arr
  }

  const getData = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const top10Refer = await refferCT.getTop10Refer()
      setTopReferral(top10Refer)
      const checkUserRegister = async () => {
        const isRegister = await refferCT.isReferrer(account)
        setLinkRef(getLinkRef())
        setUserIsRegister(isRegister)
      }
      checkUserRegister()
      getRefer()

      setLoadingPage(false)
    }
  }
  const handleBack = () => {
    const newArr = [...acountChild]
    newArr.pop()
    setAccountChild(newArr)
    getTotalRefferChild(0, newArr[newArr.length - 1])
  }

  React.useEffect(() => {
    getData()
  }, [account, userIsRegister, userInfos])

  React.useEffect(() => {
    if (!account) {
      return
    }
    getTotalRefferChild(0, account)
    setMyCode(account.slice(account.length - 6, account.length).toLocaleLowerCase())
    getRefferCode()
    getUserInfo()
    if (!acountChild.length && account) {
      setAccountChild([...acountChild, account])
    } else {
      setAccountChild([account])
    }
  }, [account])

  const getLinkRef = () => {
    const param = window.location.origin
    const text = `${param}?ref=${account.slice(account.length - 6, account.length).toLocaleLowerCase()}`
    return text
  }

  const getRefferCode = async () => {
    if (referBy) {
      const userReferBy = await refferCT.userInfosByCode(referBy.toLowerCase())
      if (userReferBy.user === '0x0000000000000000000000000000000000000000') {
        setShowError(true)
      } else {
        setReferCode(referBy.slice(referBy?.length - 6, referBy?.length).toLocaleLowerCase())
      }
    } else {
      const ref = localStorage.getItem('saveAdd')
      if (JSON.parse(ref)) {
        const userReferByLocal = await refferCT.userInfosByCode(JSON.parse(ref).toLowerCase())
        if (userReferByLocal.user === '0x0000000000000000000000000000000000000000') {
          setShowError(true)
        } else {
          setReferCode(JSON.parse(ref).toLocaleLowerCase())
          setShowError(false)
        }
      }
    }
  }

  const getRefer = async () => {
    const pool = await getPoolContract.pools(5)
    const interest = Number(Number(pool.commPercent.toString()) * 0.000001 * 100).toFixed(2)
    setInterest(Number(interest))
  }
  const onRegister = async () => {
    try {
      let referByW = referByWallet
      if (!referByW) {
        if (referCode) {
          const userInfosByCode = await refferCT.userInfosByCode(referCode.toLowerCase())
          referByW = userInfosByCode.user
        }
      }
      const txReceipt = await refferCT.register(referByW, myCode)
      if (txReceipt?.hash) {
        dispatch(setRefLink(`${baseRefUrl}${account}`))
        toastSuccess('Congratulations, you have successfully registered!')
        setLinkRef(getLinkRef())
        setLoadingPage(true)
        getData()
      } else {
        toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
      }
      setLoading(false)
    } catch (error) {
      console.log('onRegister error:', error)
      setLoading(false)
      toastError('Please try again. Confirm the transaction and make sure you are paying enough gas!')
    }
  }

  const handleRef = () => {
    if (userIsRegister) {
      const text = getLinkRef()
      copyText(text)
      setShowCopied(true)
    }
  }

  const handleCode = (text) => {
    copyText(text)
    setShowCopied(true)
  }

  const handleLeave = () => {
    setTimeout(() => {
      setShowCopied(false)
    }, 100)
  }

  const formatLinkRef = (ref, start, end) => {
    if (ref?.includes('0x')) {
      return truncateHash(ref, start, end)
    }
    return linkRef
  }

  const handleSearchUserCode = async () => {
    const userInfosByCode = await refferCT.userInfosByCode(userSearch.toLowerCase())
    if (userInfosByCode.user === '0x0000000000000000000000000000000000000000') {
      setResultSearch('Invalid code')
      return
    }
    setResultSearch(userInfosByCode.user)
  }

  const showResultSearch = () => {
    if (resultSearch === '') return null
    if (resultSearch === 'Invalid code')
      return <p style={{ color: 'red', marginTop: 20, textAlign: 'center' }}>Invalid code</p>
    return (
      <Text style={{ color: '#C5C5C5', marginTop: 40 }} ellipsis={true}>
        <LinkExternal
          fontSize={['14px', '16px', '18px', '20px', '22px']}
          href={getBlockExploreLink(resultSearch, 'address', CHAIN_ID)}
          ellipsis={true}
          style={{ color: '#00F0E1' }}
          color="#00F0E1"
        >
          {shortenURL(`${resultSearch}`, 16)}
        </LinkExternal>
      </Text>
    )
  }
  return (
    <>
      {loadingPage ? (
        <TrendyPageLoader />
      ) : (
        <Wrapper>
          {isMobile ? (
            <MenuMobile>
              <div style={{ display: 'flex' }}>
                {tab === 1 ? (
                  <MenuItemActive>
                    <img src="./images/V3/person.png" />
                    <Text className="title">Profile</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(1)}>
                    <img src="./images/V3/personDisabled.png" />
                    <Text className="title">Profile</Text>
                  </MenuItemDisabled>
                )}

                {tab === 2 ? (
                  <MenuItemActive>
                    <img src="./images/V3/Line.png" />
                    <Text className="title">Referrals</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(2)}>
                    <img src="./images/V3/LineDisabled.png" />
                    <Text className="title">Referrals</Text>
                  </MenuItemDisabled>
                )}
                {tab === 5 ? (
                  <MenuItemActive>
                    <img src="./images/V3/RankingActive.png" />
                    <Text className="title">Top Ranking</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(5)}>
                    <img src="./images/V3/Ranking.png" />
                    <Text className="title">Top Ranking</Text>
                  </MenuItemDisabled>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                {tab === 3 ? (
                  <MenuItemActive>
                    <img src="./images/V3/group.png" />
                    <Text className="title">Referral Dashboard V2.0</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(3)}>
                    <img src="./images/V3/groupDisabled.png" />
                    <Text className="title">Referral Dashboard V2.0</Text>
                  </MenuItemDisabled>
                )}
                {tab === 4 ? (
                  <MenuItemActive>
                    <img src="./images/V3/group.png" />
                    <Text className="title">Referral Dashboard V1.0</Text>
                  </MenuItemActive>
                ) : (
                  <MenuItemDisabled onClick={() => setTab(4)}>
                    <img src="./images/V3/groupDisabled.png" />
                    <Text className="title">Referral Dashboard V1.0</Text>
                  </MenuItemDisabled>
                )}
              </div>
            </MenuMobile>
          ) : (
            <Menu>
              {tab === 1 ? (
                <MenuItemActive>
                  <img src="./images/V3/person.png" />
                  <Text className="title">Profile</Text>
                </MenuItemActive>
              ) : (
                <MenuItemDisabled onClick={() => setTab(1)}>
                  <img src="./images/V3/personDisabled.png" />
                  <Text className="title">Profile</Text>
                </MenuItemDisabled>
              )}

              {tab === 2 ? (
                <MenuItemActive>
                  <img src="./images/V3/Line.png" />
                  <Text className="title">Referrals</Text>
                </MenuItemActive>
              ) : (
                <MenuItemDisabled onClick={() => setTab(2)}>
                  <img src="./images/V3/LineDisabled.png" />
                  <Text className="title">Referrals</Text>
                </MenuItemDisabled>
              )}

              {tab === 3 ? (
                <MenuItemActive>
                  <img src="./images/V3/group.png" />
                  <Text className="title">Referral Dashboard V2.0</Text>
                </MenuItemActive>
              ) : (
                <MenuItemDisabled onClick={() => setTab(3)}>
                  <img src="./images/V3/groupDisabled.png" />
                  <Text className="title">Referral Dashboard V2.0</Text>
                </MenuItemDisabled>
              )}
              {tab === 4 ? (
                <MenuItemActive>
                  <img src="./images/V3/group.png" />
                  <Text className="title">Referral Dashboard V1.0</Text>
                </MenuItemActive>
              ) : (
                <MenuItemDisabled onClick={() => setTab(4)}>
                  <img src="./images/V3/groupDisabled.png" />
                  <Text className="title">Referral Dashboard V1.0</Text>
                </MenuItemDisabled>
              )}
              {tab === 5 ? (
                <MenuItemActive>
                  <img src="./images/V3/RankingActive.png" />
                  <Text className="title">Top Ranking</Text>
                </MenuItemActive>
              ) : (
                <MenuItemDisabled onClick={() => setTab(5)}>
                  <img src="./images/V3/Ranking.png" />
                  <Text className="title">Top Ranking</Text>
                </MenuItemDisabled>
              )}
            </Menu>
          )}

          {tab === 2 && (
            <PageReferral
              linkRef={linkRef}
              handleRef={handleRef}
              handleLeave={handleLeave}
              userIsRegister={userIsRegister}
              showCopied={showCopied}
              referCode={referCode}
              validateReferByWallet={validateReferByWallet}
              showError={showError}
              onRegister={onRegister}
              userSearch={userSearch}
              setUserSearch={setUserSearch}
              handleSearchUserCode={handleSearchUserCode}
              showResultSearch={showResultSearch}
              setTab={setTab}
            />
          )}
          {tab === 1 && <PageProfile userInfos={userInfos} />}
          {tab === 3 && (
            <PageDashboardV2
              loadingTable={loadingTable}
              acountChild={acountChild}
              totalItemChild={totalItemChild}
              total7Level={total7Level}
              listChild={listChild}
              handleChangeChild={handleChangeChild}
              handleBack={handleBack}
              getButtonChangePage={getButtonChangePage}
            />
          )}
          {tab === 4 && (
            <PageDashboardV1
              loadingTable={loadingTable}
              acountChild={acountChild}
              totalItemChild={totalItemChild}
              total7Level={total7Level}
              listChild={listChild}
              handleChangeChild={handleChangeChild}
              handleBack={handleBack}
              getButtonChangePage={getButtonChangePage}
            />
          )}
          {tab === 5 && <TopRank top10Refer={topReferral} />}
        </Wrapper>
      )}
    </>
  )
}

export default Referral
