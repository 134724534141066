import { BrowserRouter as Router, Route, Switch, Link, useRouteMatch, useParams } from 'react-router-dom'
import Referral from 'views/Referral'
import Mining from 'views/Mining'
import HomePage from 'views/HomePage1'
import Pools from 'views/PoolV2'
import Poolsv1 from 'views/Pools2'

import Tokenomic from 'views/Tokenomic'
import AirDrops from 'views/AirDrops'
import NotFound from 'views/NotFound'
import PoolV2 from 'views/PoolV2/components/PoolDetailsV2'
import { poolBaseUrlV2 } from 'views/PoolV2/components/PoolDetailsV2/constants'
import { useContext, useEffect } from 'react'
import { poolBaseUrl } from 'views/Pools/constants'
import Pool from 'views/Pool'
import PageReview from 'views/PageReview'
import Nofitication from 'views/Nofitication'
import Vote from 'views/Vote'
// import PoolClaimed from 'views/PoolClaimed'
// import Section from 'views/Social'
// import { UserContext } from 'astore/UserContext'
// import Profile from 'views/Profile'
// import Messages from 'views/Messages'
// let userData

export const NewNav = () => {
  // const { userData, clearData } = useContext(UserContext)
  return (
    <>
      <div>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>

          <Route exact path="/pools">
            <Pools />
          </Route>
          <Route exact path="/pools_V1">
            <Poolsv1 />
          </Route>
          <Route exact path="/referral">
            <Referral />
          </Route>
          <Route exact path="/tokenomic">
            <Tokenomic />
          </Route>
          <Route exact path="/mining">
            <Mining />
          </Route>
          <Route exact path="/airdrop">
            <AirDrops />
          </Route>
          <Route exact path="/review">
            <PageReview />
          </Route>
          <Route exact path="/nofitication">
            <Nofitication />
          </Route>
          <Route exact path="/vote">
            <Vote />
          </Route>
          {/* <Route
            exact
            path="/claimedV3/:id/startTime=:startTime/interest=:interest/length=:length/accountUser=:accountUser"
          >
            <PoolClaimed />
          </Route> */}
          <Route exact path={`${poolBaseUrl}/:id/chainId=:chainId`}>
            <Pool />
          </Route>
          <Route exact path={`${poolBaseUrlV2}/:id/chainId=:chainId`}>
            <PoolV2 />
          </Route>
          <Route exact path={`${poolBaseUrl}/:id/chainId=:chainId/:account`}>
            <Pool />
          </Route>
          <Route exact path={`${poolBaseUrlV2}/:id/chainId=:chainId/:account`}>
            <PoolV2 />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </div>
    </>
  )
}
