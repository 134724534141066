import { Text } from '@pancakeswap/uikit'
import styled from 'styled-components'

const Wraper = styled.div`
  background-image: url('/images/V3/bgNofitication.svg');
  background-position: top, center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Container = styled.div`
  width: 100%;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`
const Title = styled(Text)`
  font-size: 38px;
  font-weight: 700;
  color: #f5f5f5;
  line-height: 24px;
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
`
const Label = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: #f5f5f5;
  line-height: 24px;
`

const Nofitication = () => {
  return (
    <Wraper>
      <Container>
        <img src="/images/V3/Setting.svg" width={120} height={120} />
        <img src="/images/logo.png" />
        <Title>We are under maintenance</Title>
        <Label>We are updating this feature. It will take about 12 hours. We&apos;ll back soon!</Label>
      </Container>
    </Wraper>
  )
}
export default Nofitication
