import { PropsWithChildren, useState, useEffect, useRef } from 'react'
import styled, { ThemeConsumer } from 'styled-components'
import { trendyColors } from 'style/trendyTheme'
import { Table, Th, Td, Text, Button, useModal } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { getPoolsContract, getPoolsV11Contract, getPoolsV1Contract, getPoolsV2Contract } from 'utils/contractHelpers'
import CountUp from 'react-countup'
import { isMobile } from 'react-device-detect'
import contracts from 'config/constants/contracts'
import { Pool, timeDisplay } from 'views/Pools2/util'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getContract } from 'utils'
import moment from 'moment'
import { formatEther } from '@ethersproject/units'
import Dots from 'components/Loader/Dots'
import axios from 'axios'
import useOnScreen from 'hooks/useOnScreen'
import ProgressBar from '../progressBar'
import numeral from 'numeral'
import DepositPoolModal from '../DepositModal'
import ClaimPoolModal from '../ClaimModal'
// STYLE
const TableScroll = styled.div`
  max-width: 100%;
  width: 100%;
  &.scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &.scroll::-webkit-scrollbar-track {
    background-color: white;
  }
  &.scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 240, 225);
  }
  @media screen and (max-width: 480px) {
    overflow-x: auto;
    width: 100%;
  }
  Td {
    border-bottom: 0;
  }
`

const TableHeader = styled.span`
  font-size: 26px;
`
const TablePool = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  table > thead {
    tr {
      font-weight: 300;
      border-bottom: 1px solid ${trendyColors.BLACK};
    }
  }
  width: auto;
  @media screen and (max-width: 967px) {
    width: 700px;
  }
  @media screen and (max-width: 851px) {
    width: 570px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    .hiden {
      display: none;
    }
  }
  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const AmountData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`
const PageGruop = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  .active {
    background: linear-gradient(153.15deg, #7c07d8 8.57%, rgba(129, 69, 255, 0.02) 100%);
  }
`
const Page = styled.div`
  width: 30px;
  height: 30px;
  background: gray;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
`
const ButtonArea = styled.div`
  width: 1000px;
  @media screen and (max-width: 967px) {
    width: 700px;
  }
  @media screen and (max-width: 851px) {
    width: 570px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 851px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`
const ThreeDots = styled.p`
  font-size: 24px;
  color: white;
  text-align: center;
  margin: 20px 0;
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const responsiveTextSize = ['11px', '12px', '16px', '18px', '20px']
const responsiveTextSizeBNB = ['9px', '10px', '12px', '14px', '16px']
const responsiveTextSizeHeader = ['16px', '18px', '22px', '26px', '30px']

const TableDataPool: React.FC<
  PropsWithChildren<{ accountUser: any; poolInfo: Pool; userClaimedLength: number; handleSuccess: any }>
> = ({
  poolInfo,
  accountUser,
  // userClaimedLength,
  handleSuccess,
  ...props
}) => {
  const { account, chainId } = useActiveWeb3React()
  // account ='0x3AAae09dAA5b5862a8BaBc8a28F1EE826404DF7C'
  const poolsContract = getPoolsContract(chainId)
  const poolsContractV1 = getPoolsV1Contract(chainId)
  const poolsContractV11 = getPoolsV11Contract(chainId)
  const poolsContractV2 = getPoolsV2Contract(chainId)
  const { t } = useTranslation()
  const [now, setNow] = useState(0)
  const [usersClaimed, setUserClaimed] = useState([])
  const [usersClaimedLEngth, setUserClaimedLength] = useState(0)
  const cuerrentPage = useRef(0)
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const amount = useRef('')
  const [priceMaticUsd, setPriceMaticUsd] = useState(0)
  const [priceMaticUsdDeposit, setPriceMaticUsdDeposit] = useState(0)
  const currentPriceMaticUsd = useRef(0)
  const [isLoading, setIsLoading] = useState(false)
  const [totals, setTotals] = useState({
    totalClaimedUsd: 0,
  })
  const [totalMaxout, setTotalMaxout] = useState(0)
  var arrV1 = []

  const successClaimed = async () => {
    try {
      setIsLoading(true)
      handleSuccess()
      const data = await axios.get(
        `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
          accountUser ? accountUser : account
        }&poolId=${poolInfo.pid}`,
      )
      setUserClaimed(
        data.data.data.claimed.map((items: any, index: number) => {
          return {
            amount: items.amount,
            amountUsd: items.amountUsd,
            date: items.date,
            interest: poolInfo.currentInterest,
            totalLock: poolInfo.userTotalLock,
          }
        }),
      )
      const totalClaimed = data.data.data.claimed.map((i) => i.amountUsd).reduce((acc, val) => acc + val, 0)
      setTotals({
        totalClaimedUsd: totalClaimed,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    setInterval(() => {
      setNow(moment().unix())
    }, 1000)
  }, [])
  // useEffect(() => {
  //   getPriceMaticUsd()
  // }, [])
  const getPriceMaticUsd = async () => {
    try {
      const data = await axios.get(
        `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history?time=${poolInfo.startTime * 1000}`,
      )
      const arr = data.data.data.price_history[0]
      console.log('getPriceMaticUsd', arr[1])
      setPriceMaticUsd(arr[1])
      return arr[1]
    } catch (error) {
      console.log('error call usd', error)
      throw new Error()
    }
  }
  const getPool = async () => {
    const interest = (((poolInfo.currentInterest * 10000) / 365) * 730) / 1000000
    try {
      setIsLoading(true)
      const [
        UserClaimedLength,
        UserClaimedLengthV1,
        UserClaimedLengthV11,
        UserClaimedLengthV2,
        UserClaimedLengthCurrent,
      ] = await Promise.all([
        poolsContract.getUsersClaimedLength(poolInfo.pid, accountUser ? accountUser : account),
        poolsContractV1.getUsersClaimedLength(poolInfo.pid, accountUser ? accountUser : account),
        poolsContractV11.getUsersClaimedLength(poolInfo.pid, accountUser ? accountUser : account),
        poolsContractV2.getUsersClaimedLength(poolInfo.pid, accountUser ? accountUser : account),
        poolsContractV11.getUsersClaimedLengthV1(poolInfo.pid, accountUser ? accountUser : account),
      ])
      const totalUserClaimedLength =
        Number(UserClaimedLength) +
        Number(UserClaimedLengthV1) +
        Number(UserClaimedLengthV11) +
        Number(UserClaimedLengthV2) +
        Number(UserClaimedLengthCurrent)
      setUserClaimedLength(totalUserClaimedLength)
      setTotalMaxout(interest * (poolInfo.userTotalLock * (await getPriceMaticUsd())))
      console.log('totalUserClaimedLength', totalUserClaimedLength)
      if (totalUserClaimedLength > 0) {
        const dataClaimedApi = await axios.get(
          `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
            accountUser ? accountUser : account
          }&poolId=${poolInfo.pid}&dateDeposit=${poolInfo.startTime}&totalLock=${poolInfo.userTotalLock}&remainReward=${
            poolInfo.remainReward
          }&usersClaimedLength=${totalUserClaimedLength}`,
        )
        const arrApi = dataClaimedApi.data.data
        const data = await axios.get(
          `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history?time=${poolInfo.startTime * 1000}`,
        )

        const priceUsd = data.data.data.price_history[0][1]
        // setPriceMaticUsdDeposit(arrApi.priceDepositUsd)
        setPriceMaticUsdDeposit(priceUsd)
        currentPriceMaticUsd.current = arrApi.priceUsd
        const [dataClaimed, dataClaimedV1, dataClaimedV11, dataClaimedV2] = await Promise.all([
          poolsContract.getUsersClaimed(poolInfo.pid, accountUser ? accountUser : account, totalUserClaimedLength, 0),
          poolsContractV1.getUsersClaimed(poolInfo.pid, accountUser ? accountUser : account, totalUserClaimedLength, 0),
          poolsContractV11.getUsersClaimed(
            poolInfo.pid,
            accountUser ? accountUser : account,
            totalUserClaimedLength,
            0,
          ),
          poolsContractV2.getUsersClaimed(poolInfo.pid, accountUser ? accountUser : account, totalUserClaimedLength, 0),
        ])
        console.log('check claimed length', arrApi.claimed.length, arrApi.usersClaimedLength, arrApi.claimedV1)
        // if (arrApi.claimed.length > 0 && arrApi.usersClaimedLength + arrApi.claimedV1 === arrApi.claimed.length) {
        if (arrApi.claimed.length > 0) {
          setUserClaimed(
            arrApi.claimed.map((items: any, index: number) => {
              return {
                amount: items.amount,
                amountUsd: items.amountUsd,
                date: items.date,
                interest: poolInfo.currentInterest,
                totalLock: poolInfo.userTotalLock,
              }
            }),
          )
          const totalClaimed = arrApi.claimed.reduce((acc, val) => acc + val.amountUsd, 0)
          console.log('totalClaimed', totalClaimed)
          setTotals({
            totalClaimedUsd: totalClaimed,
            // eslint-disable-next-line no-await-in-loop
          })
          setIsLoading(false)
        } else {
          if (Number(UserClaimedLengthCurrent) > 0) {
            try {
              const promises = Array.from({ length: Number(UserClaimedLengthCurrent) }, async (_, i) => {
                return poolsContractV11.userClaimedV1(accountUser ? accountUser : account, poolInfo.pid, i)
              })

              arrV1 = await Promise.all(promises)
            } catch (err) {
              console.log(err)
            }
          }
          const arrClaimed = (...mang: any[][]) => [].concat(...mang).filter((item) => item.length > 0)
          const array = arrClaimed(dataClaimed.list, dataClaimedV1.list, dataClaimedV11.list, dataClaimedV2.list, arrV1)

          const dataUserClaimed = array.map((claimed: any, i: number) => {
            return {
              amount: Number(formatEther(claimed.amount)),
              date: Number(claimed.date.toString()),
              interest: (Number(claimed.interrest.toString()) / 10000) * 365,
              totalLock: Number(formatEther(claimed.totalLock)),
            }
          })
          const sleep = (ms) => {
            return new Promise((resolve) => setTimeout(resolve, ms))
          }
          console.log(dataUserClaimed)
          console.log(arrApi.claimed)
          if (arrApi.claimed.length === 0) {
            for (
              let index = !arrApi || arrApi.length === 0 ? 0 : arrApi.claimed.length;
              index < dataUserClaimed.length;
              index++
            ) {
              setIsLoading(true)
              // eslint-disable-next-line no-await-in-loop
              await sleep(5000)
              // eslint-disable-next-line no-await-in-loop
              const data = await axios.get(
                `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
                  accountUser ? accountUser : account
                }&poolId=${poolInfo.pid}&dateDeposit=${poolInfo.startTime}&dateClaimed=${
                  dataUserClaimed[index].date * 86400
                }&amount=${dataUserClaimed[index].amount}`,
              )
              if (data.data.data.claimed.length === totalUserClaimedLength) {
                setIsLoading(false)
                // eslint-disable-next-line no-await-in-loop
                const result = await axios.get(
                  `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
                    accountUser ? accountUser : account
                  }&poolId=${poolInfo.pid}&dateDeposit=${poolInfo.startTime}`,
                )
                setUserClaimed(
                  dataUserClaimed.map((items: any, index: number) => {
                    return {
                      amount: items.amount,
                      amountUsd: result.data.data.claimed[index].amountUsd,
                      date: result.data.data.claimed[index].date,
                      interest: items.interest,
                      totalLock: items.totalLock,
                    }
                  }),
                )
                const totalClaimed = result.data.data.claimed.map((i) => i.amountUsd).reduce((acc, val) => acc + val, 0)
                setTotals({
                  totalClaimedUsd: totalClaimed,
                  // eslint-disable-next-line no-await-in-loop
                })
                break
              }
            }
          } else {
            const getDataResult = async (i) => {
              if (i < totalUserClaimedLength) {
                const updateClaimed = await axios.get(
                  `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
                    accountUser ? accountUser : account
                  }&poolId=${poolInfo.pid}&dateClaimed=${dataUserClaimed[i].date * 86400}&amount=${
                    dataUserClaimed[i].amount
                  }`,
                )

                await sleep(5000)
                await getDataResult(i + 1)
              } else {
                setIsLoading(false)
                // eslint-disable-next-line no-await-in-loop
                const result = await axios.get(
                  `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/getUsers?account=${
                    accountUser ? accountUser : account
                  }&poolId=${poolInfo.pid}&dateDeposit=${poolInfo.startTime}`,
                )

                setUserClaimed(
                  dataUserClaimed.map((items: any, index: number) => {
                    return {
                      amount: items.amount,
                      amountUsd: result.data.data.claimed[index] ? result.data.data.claimed[index].amountUsd : 0,
                      date: moment.unix(items.date * 86400).format('YYYY-MM-DD'),
                      interest: items.interest,
                      totalLock: items.totalLock,
                    }
                  }),
                )
                const totalClaimed = result.data.data.claimed.map((i) => i.amountUsd).reduce((acc, val) => acc + val, 0)
                setTotals({
                  totalClaimedUsd: totalClaimed,
                  // eslint-disable-next-line no-await-in-loop
                })
              }
            }
            getDataResult(arrApi.claimed.length - 1)
          }
        }
      } else {
        setIsLoading(false)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const sortData = () => {
    usersClaimed.sort(function (a, b) {
      const dateA = moment(a.date, 'DD-MM-YYYY')
      const dateB = moment(b.date, 'DD-MM-YYYY')
      return dateB.diff(dateA)
    })
    return usersClaimed
  }
  const newData = () => {
    const indexPage = cuerrentPage.current * 5
    const lastPage = (cuerrentPage.current + 1) * 5
    const array = sortData().slice(indexPage, lastPage)
    return array
  }
  const getTotalPage = () => {
    if (usersClaimed.length > 0) {
      const arrayPage = Array.from({ length: Math.ceil(usersClaimed.length / 5) }, (_, i) => i + 1)
      return arrayPage
    }
    return []
  }
  const handleChangePage = (r) => {
    cuerrentPage.current = r
    newData()
  }

  const [openDepositModal] = useModal(
    <DepositPoolModal pool={poolInfo} onSuccess={handleSuccess} account={account} chainId={chainId} />,
    true,
  )

  // ===> check to open registration modal
  const handleOpenDepositModal = () => {
    // if (isRef === true) {
    //   openModalCheckRegisterModal()
    // } else openDepositModal()
    openDepositModal()
  }

  const [openClaimModal] = useModal(
    <ClaimPoolModal account={account} onSuccess={successClaimed} pool={poolInfo} />,
    true,
  )
  const renderClaimHistory = () => {
    return (
      <>
        {usersClaimedLEngth > 0 && (
          <tr>
            <Td textAlign={'left'}>
              <Text fontSize={responsiveTextSize}>Total</Text>
            </Td>
            <Td textAlign={'center'}>
              <Text fontSize={responsiveTextSize}>
                <CountUp
                  separator=","
                  start={0}
                  preserveValue
                  delay={0}
                  end={Number(poolInfo.currentInterest)}
                  decimals={2}
                  duration={0.5}
                ></CountUp>
              </Text>
            </Td>
            <Td textAlign={'right'}>
              {poolInfo.userTotalLock === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text fontSize={responsiveTextSize}>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={poolInfo.userTotalLock * priceMaticUsdDeposit}
                      decimals={poolInfo.userTotalLock > 0 ? 2 : 0}
                      duration={0.5}
                    />{' '}
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'right'}>
              {totalMaxout === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text fontSize={responsiveTextSize}>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={totalMaxout}
                      decimals={2}
                      duration={0.5}
                    />{' '}
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'right'}>
              {Number(totals.totalClaimedUsd === 0) ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text fontSize={responsiveTextSize}>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={totals.totalClaimedUsd}
                      decimals={totals.totalClaimedUsd > 0 ? 2 : 0}
                      duration={0.5}
                      style={{ marginRight: 6 }}
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'right'}>
              {poolInfo.currentReward === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text fontSize={responsiveTextSize}>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={poolInfo.currentReward * currentPriceMaticUsd.current}
                      decimals={poolInfo.currentReward > 0 ? 2 : 0}
                      duration={0.5}
                      style={{ marginRight: 6 }}
                    />
                  </Text>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    ~
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      end={poolInfo.currentReward}
                      decimals={poolInfo.currentReward > 0 ? 2 : 0}
                      duration={0.5}
                    />
                    <img src={`/images/chains/${chainId}.png`} alt="pool name" width={18} style={{ marginLeft: 6 }} />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'center'}>
              <Text fontSize={responsiveTextSize}>
                <span>{poolInfo.currentReward <= 0 ? 'Waiting' : 'Claimable'}</span>
              </Text>
            </Td>
          </tr>
        )}
        {/* {renderHistory()} */}
      </>
    )
  }
  const renderHistory = () => {
    return (
      <>
        {usersClaimedLEngth > 0 &&
          newData().map((claimHistory, index) => {
            return (
              <tr key={index}>
                <Td textAlign={'right'}>
                  <Text fontSize={responsiveTextSize}>
                    {' '}
                    {typeof claimHistory.date === 'string'
                      ? moment(claimHistory.date, 'DD-MM-YYYY').format('DD-MM-YYYY')
                      : moment.unix(claimHistory.date * 86400).format('DD-MM-YYYY')}
                  </Text>
                </Td>
                <Td textAlign={'center'}>
                  <Text fontSize={responsiveTextSize}> {claimHistory.interest.toFixed(2)}</Text>
                </Td>
                <Td textAlign={'right'}>
                  {poolInfo.userTotalLock === 0 ? (
                    <Text fontSize={responsiveTextSize}>0</Text>
                  ) : (
                    <AmountData>
                      <Text fontSize={responsiveTextSize}>
                        $
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={poolInfo.userTotalLock * priceMaticUsdDeposit}
                          decimals={poolInfo.userTotalLock > 0 ? 2 : 0}
                          duration={0.5}
                        />{' '}
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'right'}>
                  <AmountData>
                    <Text fontSize={responsiveTextSize}>---/---</Text>
                  </AmountData>
                </Td>
                {/* <Td textAlign={'right'}>
                  {claimHistory.totalLock === 0 ? (
                    <Text fontSize={responsiveTextSize}>0</Text>
                  ) : (
                    <AmountData>
                      <Text fontSize={responsiveTextSize}>
                        $
                        <CountUp
                              separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={claimHistory.totalLock * priceMaticUsd.current}
                          decimals={claimHistory.totalLock > 0 ? 2 : 0}
                          duration={0.5}
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td> */}
                <Td textAlign={'right'}>
                  <AmountData>
                    <Text fontSize={responsiveTextSize}>---/---</Text>
                  </AmountData>
                </Td>
                <Td textAlign={'right'}>
                  {claimHistory.amount === 0 ? (
                    <Text fontSize={responsiveTextSize}>0</Text>
                  ) : (
                    <AmountData>
                      <Text fontSize={responsiveTextSize}>
                        $
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={claimHistory.amountUsd}
                          decimals={claimHistory.amount > 0 ? 2 : 0}
                          duration={0.5}
                        />
                      </Text>
                      <Text fontSize={responsiveTextSizeBNB}>
                        ~
                        <CountUp
                          separator=","
                          start={0}
                          preserveValue
                          delay={0}
                          end={claimHistory.amount}
                          decimals={claimHistory.amount > 0 ? 2 : 0}
                          duration={0.5}
                        />
                        <img
                          src={`/images/chains/${chainId}.png`}
                          alt="pool name"
                          width={18}
                          style={{ marginLeft: 6 }}
                        />
                      </Text>
                    </AmountData>
                  )}
                </Td>
                <Td textAlign={'center'}>
                  <Text fontSize={responsiveTextSize}>Claimed</Text>
                </Td>
              </tr>
            )
          })}
      </>
    )
  }
  const progress = Math.round((totals.totalClaimedUsd / totalMaxout) * 100)
  useEffect(() => {
    getPool()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])
  return (
    // <div ref={ref}></div>
    <>
      <div style={{ width: '100%' }} ref={ref}>
        {
          <ProgressBar
            title={`$ ${numeral(totals.totalClaimedUsd).format('0,0')} / $ ${numeral(totalMaxout).format('0,0')}`}
            isVisible={isVisible}
            progress={progress}
          />
        }
      </div>

      <TableHeader>
        <Text fontSize={responsiveTextSizeHeader}>Your Income </Text>
      </TableHeader>
      <TablePool>
        <TableScroll className="scroll">
          <thead>
            <tr>
              <Th textAlign="left">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  Date Time
                </Text>
              </Th>
              <Th textAlign="center">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  Interest (%)
                </Text>
              </Th>
              <Th textAlign="right">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  TotalLock
                </Text>
              </Th>
              <Th textAlign="right">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  TotalMaxOut
                </Text>
              </Th>
              <Th textAlign="right">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  TotalClaimed
                </Text>
              </Th>
              <Th textAlign="right">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  Your Income
                </Text>
              </Th>
              <Th textAlign="center">
                <Text color={trendyColors.DARK_PURPLE} fontSize={responsiveTextSize} textTransform="capitalize">
                  Status
                </Text>
              </Th>
            </tr>
          </thead>
          <tbody>
            <>{renderClaimHistory()}</>

            {isLoading === true ? (
              <ThreeDots className="loading">
                Loading<span>.</span>
                <span>.</span>
                <span>.</span>
              </ThreeDots>
            ) : (
              <>
                <>{renderHistory()}</>
              </>
            )}
          </tbody>
        </TableScroll>
      </TablePool>
      {isLoading === false && getTotalPage().length > 0 && (
        <PageGruop>
          {getTotalPage().map((i, r) => (
            <Page className={r === cuerrentPage.current ? 'active' : ''} onClick={() => handleChangePage(r)}>
              {i}
            </Page>
          ))}
        </PageGruop>
      )}
      <ButtonArea>
        <Button
          variant="primary"
          width={['120px', '150px', '180px', '200px']}
          onClick={() => handleOpenDepositModal()}
          scale={isMobile ? 'sm' : 'md'}
          disabled={
            chainId === 97
              ? poolInfo.startTime > 0 && now - poolInfo.startTime > 3600
              : poolInfo.startTime > 0 && now - poolInfo.startTime > 604800
          }
        >
          Deposit
        </Button>
        <Button
          style={{ color: '#6216B0', backgroundColor: '#D9D9D9' }}
          variant={poolInfo.currentReward > 0 ? 'danger' : 'light'}
          disabled={poolInfo.currentReward === 0 || totals.totalClaimedUsd >= totalMaxout || accountUser}
          width={['120px', '150px', '180px', '200px']}
          onClick={openClaimModal}
          scale={isMobile ? 'sm' : 'md'}
        >
          Claim
        </Button>
      </ButtonArea>
    </>
  )
}
export default TableDataPool
