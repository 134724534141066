import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { getContract, getPoolsV4Contract, getPoolsV44Contract } from 'utils/contractHelpers'
import addresses from '../../config/constants/contracts'
import voteAbi from '../../config/abi/vote.json'
import { useBalance, useSigner } from 'wagmi'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import refferalAbi from 'config/abi/refferal.json'
import pools4ABI from 'config/abi/pools4.json'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { ModalCheckRegister } from 'components/ModalRegister/ModalCheckRegister'
import { Button, Text, useModal, useToast } from '@pancakeswap/uikit'
import useVoteConfirmTransaction from 'hooks/useVoteConfirmTransaction'
import { ToastDescriptionWithTx } from 'components/Toast'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import { formatBigNumber } from 'utils/formatBalance'
import CountUp from 'react-countup'
import { ChainId, NATIVE } from '../../../packages/swap-sdk/src/constants'
import { formatEther } from '@ethersproject/units'
import numeral from 'numeral'
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  margin: 150px 0;
  position: relative;
  padding: 20px;
  @media screen and (max-width: 575px) {
    margin: 80px 0;
  }
`
const Container = styled.div`
  align-items: center;
  display: flex;
  border-radius: 20px;
  padding: 40px;
  gap: 20px;
  border: 1px;
  flex-direction: column;
  max-width: 855px;
  @media screen and (max-width: 575px) {
    padding: 20px 10px;
  }
`
const DateTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 490px;
  border-radius: 12px;
  padding: 0 20px;
  background: #ffffff1a;
  border: 1px solid #8544f5;
  height: 49px;
  align-items: center;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const TextContainer = styled.div`
  max-width: 660px;
  margin-bottom: 10px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const TextContent = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  letter-spacing: 0em;
  word-wrap: break-word;
  text-align: justify;
  color: #adabb2;
  @media screen and (max-width: 575px) {
    font-size: 14px;
    line-height: 18px;
  }
`
const Progress = styled.div`
  width: 624px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const Title = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: center;
  margin: 20px 0;
  @media screen and (max-width: 575px) {
    font-size: 18px;
    line-height: 24px;
  }
`
const Label = styled(Text)`
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #adabb2;
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 16px;
    line-height: 24px;
  }
`
function Vote() {
  let CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const { account, chainId } = useWeb3React()
  const { data: signer } = useSigner()
  const voteCt = getContract({ address: addresses.vote[CHAIN_ID], abi: voteAbi, chainId: CHAIN_ID, signer })
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const poolV44Contract = getContract({
    address: addresses.poolsV44[CHAIN_ID],
    abi: pools4ABI,
    chainId: CHAIN_ID,
    signer,
  })
  const [loadingPage, setLoadingPage] = useState(true)
  const [isRegister, setIsRegister] = useState(true)
  const [usersIsVote, setUserIsVote] = useState(false)
  const [totalVoted, setTotalVoted] = useState(0)
  const [userVoted, setUserVoted] = useState(0)
  const getPoolV4Contract = getPoolsV4Contract(CHAIN_ID)
  const getPoolV44Contract = getPoolsV44Contract(CHAIN_ID)
  const [totalLock, setToalLock] = useState(0)
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()
  const [openModal, onDismiss] = useModal(
    <ModalCheckRegister onCheck={() => checkRegister()} onDismiss={() => onDismiss} />,
    true,
    false,
    'removeModalCheckRegister',
  )

  useEffect(() => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      checkRegister()
      checkVote()
      userTotal()
    }
  }, [account])

  const checkRegister = async () => {
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const usersRegister = await refferCT.isReferrer(account)
      setIsRegister(usersRegister)
    }
  }
  const handleRegister = () => {
    openModal()
  }
  const checkVote = async () => {
    if (!account) {
      setLoadingPage(true)
    } // else if (moment().unix() < 1730120400 || moment().unix() > 1730379600) setUserIsVote(true)
    else {
      setLoadingPage(false)
      // const users = await voteCt.users(account)
      // setUserIsVote(users)
    }
  }
  const { isVotting, isVoted, isConfirmedVote, isConfirmingVote, handleVote, handleConfirmVote } =
    useVoteConfirmTransaction({
      onRequiresVoteval: async () => {
        return Promise.resolve(true)
      },
      onVote: () => {
        // console.log(111)
        setLoadingPage(true)
        return poolV44Contract.voteConfig(1, true)
      },
      onVoteSuccess: async ({ receipt }) => {
        toastSuccess(t('Congratdataulations vote success'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
        setLoadingPage(false)
        await checkVote()
      },
      onConfirm: (receipt) => {
        return receipt
      },
      onSuccess: async ({ receipt }) => {
        setLoadingPage(false)
        return Promise.resolve(1)
      },
    })
  const { data, isFetched } = useBalance({
    addressOrName: addresses.poolsV44[CHAIN_ID],
  })
  // const { data: data2, isFetched: isFetched2 } = useBalance({
  //   addressOrName: addresses.poolsV2[CHAIN_ID],
  // })
  const balance = isFetched && data && data.value ? formatBigNumber(data.value, 6) : 0
  const tagert = Number(balance)

  const userTotal = async () => {
    const voteId = 20
    if (!account) {
      setLoadingPage(true)
    } else {
      setLoadingPage(false)
      const uv = await poolV44Contract.voteConfigs(1, voteId)
      const isvotedd = await poolV44Contract.userVoteConfig(account, 1, voteId)
      // const totalLockV24 = await getPoolV44Contract.usdTotalLock()
      // console.log(Number(formatEther(uv.totalVote)), tagert)
      setUserIsVote(isvotedd)
      setToalLock(tagert)
      setTotalVoted(Number(formatEther(uv.totalVote)))

      // setUserVoted(uv.totalVote.toString())
    }
  }
  return (
    <>
      {loadingPage === true ? (
        <TrendyPageLoader />
      ) : (
        <Wrapper>
          <Container
            style={{
              background: '#150A27',
              border: '2px solid #8544F5',
            }}
          >
            <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
              <img src="/images/V3/newLogo.svg" />
            </div>
            {/* <DateTime> */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      display: 'flex', */}
            {/*      flexDirection: 'row', */}
            {/*      gap: '10px', */}
            {/*    }} */}
            {/*  > */}
            {/*    <h1 style={{ color: '#ADABB2' }}>Start date: 28/10/2024</h1> */}
            {/*  </div> */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      display: 'flex', */}
            {/*      flexDirection: 'row', */}
            {/*      gap: '10px', */}
            {/*    }} */}
            {/*  > */}
            {/*    <h1 style={{ color: '#ADABB2' }}>End date: 31/10/2024</h1> */}
            {/*  </div> */}
            {/* </DateTime> */}
            <Title>This is the Voting Round for Ver 1.0.</Title>
            <Label>
              With POL in the accumulation phase, the price has dropped to the $0.35 mark—a key level for Trendy
              DeFi&apos;s Polygon Pool holders. To support members, we’re proposing a vote to temporarily adjust the POL
              claim ratio to 35%. Once POL returns to $0.60, the claim ratio will revert to its previous level. However,
              the final decision rests with the community members.
            </Label>
            {/* <TextContainer> */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      display: 'flex', */}
            {/*      flexDirection: 'row', */}
            {/*      gap: '10px', */}
            {/*      margin: '15px 0', */}
            {/*      alignItems: 'center', */}
            {/*    }} */}
            {/*  > */}
            {/*    <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}> */}
            {/*      ✦ */}
            {/*    </Text> */}
            {/*    <TextContent> */}
            {/*      The voting period will take place from 13:00 on October 28th to 13:00 on October 31th, 2024 (UTC). */}
            {/*    </TextContent> */}
            {/*  </div> */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      display: 'flex', */}
            {/*      flexDirection: 'row', */}
            {/*      gap: '10px', */}
            {/*      margin: '15px 0', */}
            {/*      alignItems: 'center', */}
            {/*    }} */}
            {/*  > */}
            {/*    <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}> */}
            {/*      ✦ */}
            {/*    </Text> */}
            {/*    <TextContent> */}
            {/*      All Trendy investors are eligible to vote (each person is entitled to one vote, regardless of the */}
            {/*      amount invested). */}
            {/*    </TextContent> */}
            {/*  </div> */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      display: 'flex', */}
            {/*      flexDirection: 'row', */}
            {/*      gap: '10px', */}
            {/*      margin: '15px 0', */}
            {/*      alignItems: 'center', */}
            {/*    }} */}
            {/*  > */}
            {/*    <Text style={{ color: '#8544F5' }} fontSize={24} fontWeight={700}> */}
            {/*      ✦ */}
            {/*    </Text> */}
            {/*    <TextContent>If there are more than 51% consensus rate, the proposal will be implemented.</TextContent> */}
            {/*  </div> */}
            {/* </TextContainer> */}

            <Progress>
              <progress
                value={totalVoted}
                max={totalLock}
                style={{ margin: '4px 0', accentColor: '#8544F5', height: 40, width: '100%' }}
              />

              <div style={{ display: 'flex', justifyContent: 'space-between', color: 'white', marginBottom: 10 }}>
                <span>0%</span>
                <span>
                  voted ver 1.0: {(((totalVoted > totalLock ? totalLock : totalVoted) * 100) / totalLock).toFixed(0)} %
                </span>
                <span>100%</span>
              </div>
            </Progress>
            {isRegister === false ? (
              <Button
                style={{
                  width: '161px',
                  height: '41px',
                  borderRadius: '20px',
                  fontWeight: '700',
                  cursor: 'pointer',
                  padding: '20px',
                  marginBottom: 10,
                  boxShadow: '2px 2px 8px 16px #0000001A',
                  background: '#8544F5',
                }}
                type="button"
                onClick={handleRegister}
              >
                <h1 style={{ fontSize: '20px', color: '#E2E1E5' }}>Register</h1>
              </Button>
            ) : (
              <Button
                style={{
                  width: '161px',
                  height: '41px',
                  borderRadius: '20px',
                  fontWeight: '700',
                  cursor: 'pointer',
                  padding: '20px',
                  marginBottom: 10,
                  boxShadow: '2px 2px 8px 16px #0000001A',
                  background: '#8544F5',
                }}
                type="button"
                className="buttonVote"
                onClick={handleVote}
                disabled={usersIsVote}
              >
                <h1 style={{ fontSize: '20px', color: '#E2E1E5' }}>Vote</h1>
              </Button>
            )}
          </Container>
        </Wrapper>
      )}
    </>
  )
}

export default Vote
