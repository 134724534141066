import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input, useMatchBreakpoints } from '@pancakeswap/uikit'
import { useState, useEffect } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import { InjectedProps } from '@pancakeswap/uikit/src/widgets/Modal/types'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import styled from 'styled-components'
import Link from 'next/link'
// import { Modal } from 'antd'
import Image from 'next/image'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
interface RegistersModalProps extends InjectedProps {}

const StyledInput = styled(Input)`
  outline: none;
  border: 3px solid #009571;
  border-radius: 10px;
`

export const ModalBan = ({ onDismiss }: { onDismiss: () => void }) => {
  const { account } = useActiveWeb3React()
  const { isMobile } = useMatchBreakpoints()
  var newLocal = []
  const handleCancel = () => {
    window.location.replace(window.location.origin)
  }

  //   const handleOk = () => {
  //     if (!localStorage.getItem('isVoted')) {
  //       newLocal.push(account)
  //       localStorage.setItem('isVoted', JSON.stringify(newLocal))
  //       onVote()
  //       onDismiss()
  //     } else {
  //       const isVotes = JSON.parse(localStorage.getItem('isVoted'))
  //       newLocal.push(...isVotes, account)
  //       localStorage.setItem('isVoted', JSON.stringify(newLocal))
  //       onVote()
  //       onDismiss()
  //     }
  //   }
  return (
    <Modal title="Notification" onDismiss={handleCancel} style={{ width: '320px' }}>
      {/* <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'center' }}>
        <Image src="/images/logo-mobile.png" alt="" width="50px" height="50px" />
      </div> */}
      <Grid alignItems="center" justifyContent="center" style={{ marginTop: isMobile ? '60px' : '30px' }}>
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '32px', textAlign: 'center' }}>You are banned.</h1>
        <br />
      </Grid>
    </Modal>
  )
}
