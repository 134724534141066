import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  @media screen and (min-width: 1440px) {\n    margin: 10px 20px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: relative;\n  display: flex;\n  align-items: center;\n  color: #d2d6ef;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 22px;\n  // align-items: flex-start;\n  flex-direction: row-reverse;\n  font-family: Inter, sans-serif;\n  background: ",
        ";\n  border-radius: ",
        ";\n  background: ",
        ";\n  opacity: ",
        ";\n\n  ",
        "\n\n  ",
        "\n\n  &:hover {\n    background: #353547;\n    ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var StyledMenuItemContainer = styled.div.withConfig({
    componentId: "sc-34a207a0-0"
})(_templateObject());
var StyledMenuItem = styled.a.withConfig({
    componentId: "sc-34a207a0-1"
})(_templateObject1(), function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "rgba(175, 137, 238, 0.20)" : "blackrgba(39,38,44,0.7)";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "16px" : "16px";
}, function(param) {
    var $isActive = param.$isActive;
    return $isActive ? "rgba(175, 137, 238, 0.20)" : "black";
}, function(param) {
    var $isDisabled = param.$isDisabled;
    return $isDisabled ? 0.5 : 1;
}, function(param) {
    var $statusColor = param.$statusColor, theme = param.theme;
    return $statusColor && '\n    &:after {\n      content: "";\n      border-radius: 100%;\n      background: #1FC7D4;\n      height: 8px;\n      width: 8px;\n      margin-left: 12px;\n    }\n  ';
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" ? "\n    padding: 0 16px;\n    height: 48px;\n    gap: 10px;\n\n  " : "\n    padding:  20px 20px;\n    height: 50px;\n  ";
}, function(param) {
    var $variant = param.$variant;
    return $variant === "default" && "border-radius: 16px;";
});
export default StyledMenuItem;
