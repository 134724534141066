import styled from 'styled-components'
import DetailReview from './components/DetailReview'
import TopUser from './components/topUser'
import System from './components/System'

const Wrapper = styled.div`
  width: 100%;
  margin-top: 100px;
`
const PageReview = () => {
  return (
    <Wrapper>
      <System />
      <DetailReview />
      <TopUser />
    </Wrapper>
  )
}
export default PageReview
