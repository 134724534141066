import React, { PropsWithChildren, useEffect, useState } from 'react'
import styled from 'styled-components'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Button, Input, Text, useMatchBreakpoints, useToast } from '@pancakeswap/uikit'
import { getContract, getPoolsV11Contract, getPoolsV1Contract } from 'utils/contractHelpers'
import contracts from 'config/constants/contracts'
import { ChainId } from '../../../../../packages/swap-sdk/src/constants'
import poolsApi from '../../../../config/abi/pools.json'
import pools2Api from '../../../../config/abi/pools2.json'
import pools4Api from '../../../../config/abi/pools4.json'
import pools44Api from '../../../../config/abi/pools44.json'
import referalApi from '../../../../config/abi/refferal.json'
import { useSigner } from 'wagmi'
import { formatEther } from '@ethersproject/units'
import { Data } from '../../util'
import CountUp from 'react-countup'
import moment from 'moment'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import { Link, useHistory } from 'react-router-dom'
import { poolBaseUrlV2 } from 'views/PoolV2/constants'
import { poolBaseUrl } from 'views/Pool2/constants'
import { ThreeDots } from 'views/Pool/components/DepositModal'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { ToastDescriptionWithTx } from 'components/Toast'
const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
  padding: 40px;
  .loading {
    color: white;
    font-size: 32px;
  }
  @media screen and (max-width: 575px) {
    padding: 20px;
  }
`
const Container = styled.div`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid transparent;
  border-image-slice: 1;
  background-image: linear-gradient(#18171b, #18171b), radial-gradient(circle at top left, #7b3fe4 0%, #a726c1 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  @media screen and (max-width: 575px) {
    padding: 20px;
  }
`
const StyledInputSearch = styled(Input)`
  outline: none;
  height: 48px;
  width: 600px;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;

  &:focus:not(:disabled) {
    border: none;
  }
  ::placeholder {
    color: #67666e;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const ButtonSearch = styled(Button)`
  background-color: rgba(133, 68, 245, 1);
  color: white;
  width: 100px;
`
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
`
const UserTotalAll = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid transparent;
  border-image-slice: 1;

  background-image: linear-gradient(#18171b, #18171b), radial-gradient(circle at top left, #7b3fe4 0%, #a726c1 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
`
const TitleHead = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  color: white;
  @media screen and (max-width: 575px) {
    font-size: 24px;
  }
`
const Details = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`
const Label = styled(Text)`
  color: var(--greyscale-grey-scale-text-seconday, #adabb2);
  text-align: right;
  /* Text md/Medium */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`
const Total = styled(Text)`
  color: white;
  text-align: right;
  /* Text xl/Bold */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`
const UserTotalLock = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
const Ver1 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const Ver2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`
const PoolInfo = styled.div`
  width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 1px solid transparent;
  border-image-slice: 1;

  background-image: linear-gradient(#18171b, #18171b), radial-gradient(circle at top left, #7b3fe4 0%, #a726c1 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
`
const ButtonDetail = styled.div``

const DetailReview = () => {
  const history = useHistory()
  const { isMobile } = useMatchBreakpoints()
  const [isLoading, setIsLoading] = useState(false)
  const { account, chainId } = useActiveWeb3React()
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const { data: signer } = useSigner()
  const poolsContractV11 = getPoolsV11Contract(chainId)
  const poolsContractV1 = getPoolsV1Contract(chainId)
  const [inputValue, setInputValue] = useState('')
  const link = window.location.origin
  const [dataInfo, setDataInfo] = useState([])
  const [dataVer1, setDataVer1] = useState([])
  const [dataVer2, setDataVer2] = useState([])
  const [loadingVer1, setLoadingVer1] = useState(false)
  const [loadingVer2, setLoadingVer2] = useState(false)
  const [userSync, setUserSync] = useState([])
  const [showError, setShowError] = useState(false)
  const [allClaimed, setAllClaimed] = useState([])
  const [priceUsdDeposit, setPriceUsdDeposit] = useState([])
  const [price, setPrice] = useState(0)
  const [totalVolumnV1, setTotalVolumnV1] = useState(0)
  const [totalVolumnV2, setTotalVolumnV2] = useState(0)
  const { toastSuccess, toastError } = useToast()
  var dataLocation = {
    startTime: 0,
    currentInterest: 0,
    userClaimedLength: 0,
  }
  var arr
  var arrPrice = []
  var arrAll = []
  var arrV1 = []
  const checkAdmin = () => {
    const admin = process.env.NEXT_PUBLIC_DEFAULT_ACCOUNT_ADMIN
    if (account && !admin.includes(account)) {
      window.location.replace(window.location.origin)
    }
  }
  const poolsContract = getContract({
    address: contracts.pools[CHAIN_ID],
    abi: poolsApi,
    chainId: CHAIN_ID,
    signer,
  })
  const pools2Contract = getContract({
    address: contracts.poolsV2[CHAIN_ID],
    abi: pools2Api,
    chainId: CHAIN_ID,
    signer,
  })
  const pools4Contract = getContract({
    address: contracts.poolsV4[CHAIN_ID],
    abi: pools4Api,
    chainId: CHAIN_ID,
    signer,
  })
  const pools44Contract = getContract({
    address: contracts.poolsV44[CHAIN_ID],
    abi: pools44Api,
    chainId: CHAIN_ID,
    signer,
  })
  const referalContract = getContract({
    address: contracts.refferal[CHAIN_ID],
    abi: referalApi,
    chainId: CHAIN_ID,
    signer,
  })
  const dataContract = [poolsContract, pools2Contract, pools4Contract]
  const handleChange = (e) => {
    setInputValue(e)
    if (e.length !== 42) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const getNewArr = async (dataContract, accountUser, macticPrice, dataRef) => {
    const resultArr = []

    await Promise.all(
      dataContract.map(async (contract) => {
        try {
          // eslint-disable-next-line no-await-in-loop
          const userTotalLock = await contract.userTotalLock(accountUser)
          // eslint-disable-next-line no-await-in-loop
          const totalComm = await contract.totalComms(accountUser)
          // eslint-disable-next-line no-await-in-loop
          const totalReward = await contract.totalRewards(accountUser)
          // eslint-disable-next-line no-await-in-loop
          const remainComm = await contract.remainComm(accountUser)
          const arr = {
            totalLock: Number(formatEther(userTotalLock)),
            totalComm: Number(formatEther(totalComm)),
            totalReward: Number(formatEther(totalReward)),
            remainComm: Number(formatEther(remainComm)),
          }

          resultArr.push(arr)
          // eslint-disable-next-line no-await-in-loop
          await delay(2000)
        } catch (error) {
          console.error('Error fetching data:', error)
          // Xử lý lỗi nếu có.
        }
      }),
    )
    const totalLockV4 = await pools4Contract.getUserTotalLock(accountUser)
    if (Number(formatEther(totalLockV4)) === 0) {
      const totalLockV44 = await pools44Contract.getUserTotalLock(accountUser)
      if (Number(formatEther(totalLockV44)) > 0) {
        // eslint-disable-next-line no-await-in-loop
        const userTotalLock = await pools44Contract.userTotalLock(accountUser)
        // eslint-disable-next-line no-await-in-loop
        const totalComm = await pools44Contract.totalComms(accountUser)
        // eslint-disable-next-line no-await-in-loop
        const totalReward = await pools44Contract.totalRewards(accountUser)
        // eslint-disable-next-line no-await-in-loop
        const remainComm = await pools44Contract.remainComm(accountUser)
        const arr = {
          totalLock: Number(formatEther(userTotalLock)),
          totalComm: Number(formatEther(totalComm)),
          totalReward: Number(formatEther(totalReward)),
          remainComm: Number(formatEther(remainComm)),
        }

        resultArr.push(arr)
      }
    }
    const totalRemainComm = await resultArr.map((i) => i.remainComm).reduce((a, b) => a + b, 0)
    const dataTotalAll: Data = {
      totalLock:
        (await resultArr.map((i) => i.totalLock).reduce((a, b) => a + b, 0)) * Number(formatEther(macticPrice)),
      totalComm:
        (await resultArr.map((i) => i.totalComm).reduce((a, b) => a + b, 0)) * Number(formatEther(macticPrice)),
      totalDowline: Number(dataRef.totalRefer7),
      totalDirect: Number(dataRef.totalRefer),
      commClaimed:
        ((await resultArr.map((i) => i.totalComm).reduce((a, b) => a + b, 0)) - totalRemainComm) *
        Number(formatEther(macticPrice)),
    }
    setDataInfo([dataTotalAll])
    setIsLoading(false)
    return dataTotalAll
  }
  const getPoolVer1 = async (accountUser) => {
    setLoadingVer1(true)
    const volumnV1 = await poolsContract.volumeOntree(accountUser)
    const volumnV2 = await pools2Contract.volumeOntree(accountUser)
    setTotalVolumnV1(Number(formatEther(volumnV1)) + Number(formatEther(volumnV2)))
    for (let index = 0; index < 6; index++) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const pool1 = await poolsContract.users(accountUser, index)
        // eslint-disable-next-line no-await-in-loop
        const pool2 = await pools2Contract.users(accountUser, index)
        // eslint-disable-next-line no-await-in-loop
        const poolV1 = await poolsContractV1.users(accountUser, index)
        // eslint-disable-next-line no-await-in-loop
        const poolV11 = await poolsContractV11.users(accountUser, index)
        // eslint-disable-next-line no-await-in-loop
        const poolInfo = await poolsContract.pools(index)
        const currentInterest = Number(poolInfo.currentInterest)

        const totalLock =
          Number(formatEther(pool1.totalLock)) +
          Number(formatEther(pool2.totalLock)) +
          Number(formatEther(poolV1.totalLock)) +
          Number(formatEther(poolV11.totalLock))
        const startTime = Number(pool2.startTime) > 0 ? Number(pool2.startTime) : Number(pool1.startTime)
        const totalReward =
          Number(formatEther(pool1.totalReward)) +
          Number(formatEther(pool2.totalReward)) +
          Number(formatEther(poolV1.totalReward)) +
          Number(formatEther(poolV11.totalReward))
        const remainReward =
          Number(formatEther(pool1.remainReward)) +
          Number(formatEther(pool2.remainReward)) +
          Number(formatEther(poolV1.remainReward)) +
          Number(formatEther(poolV11.remainReward))
        if (totalLock > 0) {
          setLoadingVer1(false)
          // eslint-disable-next-line no-await-in-loop
          const data = await axios.get(
            `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history?time=${startTime * 1000}`,
          )

          const priceUsd = data.data.data.price_history[0][1]
          arrPrice.push(priceUsd)
          setPriceUsdDeposit(arrPrice)
          // eslint-disable-next-line no-await-in-loop
          const [UserClaimedLength, UserClaimedLengthV1, UserClaimedLengthV11, UserClaimedLengthV2] = await Promise.all(
            [
              poolsContract.getUsersClaimedLength(index, accountUser),
              poolsContractV1.getUsersClaimedLength(index, accountUser),
              poolsContractV11.getUsersClaimedLength(index, accountUser),
              pools2Contract.getUsersClaimedLength(index, accountUser),
            ],
          )
          // eslint-disable-next-line no-await-in-loop
          const userClaimedLengthV11Old = await poolsContractV11.getUsersClaimedLengthV1(index, accountUser)

          const totalUserClaimedLength =
            Number(UserClaimedLength) +
            Number(UserClaimedLengthV1) +
            Number(UserClaimedLengthV11) +
            Number(UserClaimedLengthV2)
          const totalLength = totalUserClaimedLength + Number(userClaimedLengthV11Old)
          // eslint-disable-next-line no-await-in-loop
          const [dataClaimed, dataClaimedV1, dataClaimedV11, dataClaimedV2] = await Promise.all([
            poolsContract.getUsersClaimed(index, accountUser, totalUserClaimedLength, 0),
            poolsContractV1.getUsersClaimed(index, accountUser, totalUserClaimedLength, 0),
            poolsContractV11.getUsersClaimed(index, accountUser, totalUserClaimedLength, 0),
            pools2Contract.getUsersClaimed(index, accountUser, totalUserClaimedLength, 0),
          ])
          if (Number(totalUserClaimedLength) > 0) {
            if (userClaimedLengthV11Old > 0) {
              try {
                const promises = Array.from({ length: Number(userClaimedLengthV11Old) }, async (_, i) => {
                  return poolsContractV11.userClaimedV1(accountUser, index, i)
                })
                // eslint-disable-next-line no-await-in-loop
                arrV1 = await Promise.all(promises)
              } catch (err) {
                console.log(err)
              }
            }
            const arrClaimed = (...mang: any[][]) => [].concat(...mang).filter((item) => item.length > 0)
            const array = arrClaimed(
              dataClaimed.list,
              dataClaimedV1.list,
              dataClaimedV11.list,
              dataClaimedV2.list,
              arrV1,
            )
            // eslint-disable-next-line no-await-in-loop
            const result = await Promise.all(
              array.map(async (items) => {
                const priceUsdClaimed = await axios.get(
                  `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history?time=${items.date * 86400 * 1000}`,
                )
                const amountUsd = Number(formatEther(items.amount)) * priceUsdClaimed.data.data.price_history[0][1]
                const totalLockUSD = Number(formatEther(items.totalLock)) * priceUsd
                return [
                  Number(items.date),
                  new BigNumber(Number(formatEther(items.amount))).times(getFullDecimalMultiplier(18)).toString(),
                  new BigNumber(amountUsd).times(getFullDecimalMultiplier(18)).toString(),
                  new BigNumber(Number(formatEther(items.totalLock))).times(getFullDecimalMultiplier(18)).toString(),
                  new BigNumber(totalLockUSD).times(getFullDecimalMultiplier(18)).toString(),
                  new BigNumber(Number(formatEther(items.interrest))).times(getFullDecimalMultiplier(18)).toString(),
                ]
              }),
            )
            arrAll.push(result)
            setAllClaimed(arrAll)
          }

          setDataVer1((pre) => [
            ...pre,
            {
              poolId: index,
              totalLock: totalLock * priceUsd,
              startTime,
              totalReward: totalReward * priceUsd,
              remainReward: remainReward * priceUsd,
              currentInterest,
              totalLength,
            },
          ])
          // checkUserSync(index)
        }
        // eslint-disable-next-line no-await-in-loop
        await delay(1000)
      } catch (error) {
        console.error('Error fetching data:', error)
        // Xử lý lỗi nếu có.
      } finally {
        setLoadingVer1(false)
      }
    }
  }
  const getPoolVer2 = async (accountUser) => {
    setLoadingVer2(true)
    const volumnV4 = await pools4Contract.volumeOntree(accountUser)
    const volumnV44 = await pools44Contract.volumeOntree(accountUser)
    const totalLockV44 = await pools44Contract.getUserTotalLock(accountUser)
    setTotalVolumnV2(
      Number(formatEther(volumnV44)) > Number(formatEther(volumnV4))
        ? Number(formatEther(volumnV44))
        : Number(formatEther(volumnV4)),
    )
    if (Number(totalLockV44) > 0) {
      for (let index = 0; index < 6; index++) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const pool = await pools44Contract.users(accountUser, index)

          const totalLock = Number(formatEther(pool.totalLockUSD))
          const startTime = Number(pool.startTime)

          if (totalLock > 0) {
            setDataVer2((pre) => [...pre, { poolId: index, totalLock, startTime }])
            setLoadingVer2(false)
          }
          // eslint-disable-next-line no-await-in-loop
          await delay(1000)
        } catch (error) {
          console.error('Error fetching data:', error)
          // Xử lý lỗi nếu có.
        } finally {
          setLoadingVer2(false)
        }
      }
    } else {
      for (let index = 0; index < 6; index++) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const pool = await pools4Contract.users(accountUser, index)

          const totalLock = Number(formatEther(pool.totalLockUSD))
          const startTime = Number(pool.startTime)

          if (totalLock > 0) {
            setDataVer2((pre) => [...pre, { poolId: index, totalLock, startTime }])
            setLoadingVer2(false)
          }
          // eslint-disable-next-line no-await-in-loop
          await delay(1000)
        } catch (error) {
          console.error('Error fetching data:', error)
          // Xử lý lỗi nếu có.
        } finally {
          setLoadingVer2(false)
        }
      }
    }
  }
  const getData = async (accountUser) => {
    try {
      setIsLoading(true)
      clear()
      const macticPrice = await pools4Contract.MATIC2USDT()
      const dataRef = await referalContract.userInfos(accountUser)
      setPrice(Number(formatEther(macticPrice)))
      await getNewArr(dataContract, accountUser, macticPrice, dataRef)
        .then((result) => {
          // Handle the result here
          arr = result
        })
        .catch((error) => {
          console.error('Error:', error)
        })
      if (arr.totalLock > 0) {
        await getPoolVer1(inputValue)
        await getPoolVer2(inputValue)
      }
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }
  const clear = () => {
    setDataInfo([])
    setDataVer1([])
    setDataVer2([])
    setAllClaimed([])
    setPriceUsdDeposit([])
  }
  const handleClick = (accountUser, poolId, startTime, currentInterest, userClaimedLength) => {
    history.push({
      pathname: '/claimedV3',
      state: {
        data: {
          accountUser,
          poolId,
          startTime,
          currentInterest,
          userClaimedLength,
        },
      },
    })
  }
  useEffect(() => {
    checkAdmin()
  }, [account])
  const totalLockUSD =
    dataVer1.map((i) => i.totalLock).reduce((a, b) => a + b, 0) +
    dataVer2.map((i) => i.totalLock).reduce((a, b) => a + b, 0)
  return (
    <Wrapper>
      <Container>
        <div
          style={{
            display: 'flex',
            gap: isMobile ? '20px' : '50px',
            flexDirection: isMobile ? 'column' : 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <StyledInputSearch value={inputValue} onChange={(e) => handleChange(e.target.value)} />
          <ButtonSearch disabled={showError || inputValue === ''} onClick={() => getData(inputValue)}>
            Search
          </ButtonSearch>
        </div>
        {showError === true && <Text style={{ color: 'red' }}>This address is not valid</Text>}
        {isLoading === true ? (
          <TrendyPageLoader />
        ) : (
          <>
            {dataInfo.length > 0 && (
              <UserInfo>
                <UserTotalAll>
                  <TitleHead>user information</TitleHead>
                  <div style={{ width: '100%', marginTop: '20px' }}>
                    <Details>
                      <Label>TotalLock</Label>
                      <Total>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={totalLockUSD}
                          decimals={dataInfo[0].totalLock > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                    <Details>
                      <Label>TotalComm</Label>
                      <Total>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={dataInfo[0].totalComm}
                          decimals={dataInfo[0].totalComm > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                    <Details>
                      <Label>CommClaimed</Label>
                      <Total style={{ color: 'rgb(192 192 192)' }}>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={dataInfo[0].commClaimed}
                          decimals={dataInfo[0].commClaimed > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                    <Details>
                      <Label>TotalDowline</Label>
                      <Total>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={dataInfo[0].totalDowline}
                          decimals={0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                    <Details>
                      <Label>TotalDirect</Label>
                      <Total>
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={dataInfo[0].totalDirect}
                          decimals={0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                    <Details>
                      <Label>TotalVolumn</Label>
                      <Total>
                        $
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={totalVolumnV1 + totalVolumnV2}
                          decimals={totalVolumnV1 + totalVolumnV2 > 0 ? 2 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </Total>
                    </Details>
                  </div>
                </UserTotalAll>
                <UserTotalLock>
                  {loadingVer1 === true ? (
                    <ThreeDots className="loading">
                      Loading<span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </ThreeDots>
                  ) : (
                    <>
                      {dataVer1.length > 0 && (
                        <Ver1>
                          <TitleHead>Version1</TitleHead>
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '55px', marginTop: '20px' }}>
                            {dataVer1.map((items, index) => (
                              <PoolInfo key={index}>
                                <Details>
                                  <Label>Pool</Label>
                                  <Total>{items.poolId + 1}</Total>
                                </Details>
                                <Details>
                                  <Label>TotalLock</Label>
                                  <Total>
                                    $
                                    <CountUp
                                      start={0}
                                      preserveValue
                                      delay={0}
                                      end={items.totalLock}
                                      decimals={items.totalLock > 0 ? 2 : 0}
                                      duration={0.5}
                                      separator=","
                                    />
                                  </Total>
                                </Details>
                                <Details>
                                  <Label>StartTime</Label>
                                  <Total>{moment.unix(items.startTime).format('DD-MM-YYYY')}</Total>
                                </Details>
                                <Details>
                                  <Label>TotalReward</Label>
                                  <Total>
                                    $
                                    <CountUp
                                      start={0}
                                      preserveValue
                                      delay={0}
                                      end={items.totalReward}
                                      decimals={items.totalReward > 0 ? 2 : 0}
                                      duration={0.5}
                                      separator=","
                                    />
                                  </Total>
                                </Details>
                                <Details>
                                  <Label>RemainReward</Label>
                                  <Total>
                                    $
                                    <CountUp
                                      start={0}
                                      preserveValue
                                      delay={0}
                                      end={items.remainReward}
                                      decimals={items.remainReward > 0 ? 2 : 0}
                                      duration={0.5}
                                      separator=","
                                    />
                                  </Total>
                                </Details>
                                <div style={{ display: 'flex', gap: '20px' }}>
                                  <Link to={`${poolBaseUrl}/${items.poolId}/chainId=${CHAIN_ID}/${inputValue}`}>
                                    {isMobile ? (
                                      <Button
                                        style={{
                                          color: 'rgba(255, 255, 255, 1)',
                                          backgroundColor: '#141217',
                                          border: '1px solid #FFF',
                                          marginTop: '20px',
                                          borderRadius: '10px',
                                        }}
                                        variant="primary"
                                        width={'100px'}
                                        scale="md"
                                      >
                                        Detail
                                      </Button>
                                    ) : (
                                      <Button
                                        style={{
                                          color: 'rgba(255, 255, 255, 1)',
                                          backgroundColor: '#141217',
                                          border: '1px solid #FFF',
                                          borderRadius: '10px',
                                          marginTop: '20px',
                                        }}
                                        variant="primary"
                                        width={'100px'}
                                        scale="md"
                                      >
                                        Detail
                                      </Button>
                                    )}
                                  </Link>
                                </div>
                              </PoolInfo>
                            ))}
                          </div>
                        </Ver1>
                      )}
                    </>
                  )}
                  {dataVer1.length === 0 || dataVer2.length === 0 ? null : (
                    <div
                      style={{
                        width: isMobile ? '100%' : '2px',
                        height: isMobile ? '2px' : 'auto',
                        background: 'gray',
                      }}
                    ></div>
                  )}

                  {loadingVer2 === true ? (
                    <ThreeDots className="loading">
                      Loading<span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </ThreeDots>
                  ) : (
                    <>
                      {dataVer2.length > 0 && (
                        <Ver2>
                          <TitleHead>Version2</TitleHead>
                          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '55px', marginTop: '20px' }}>
                            {dataVer2.map((items, index) => (
                              <PoolInfo key={index}>
                                <Details>
                                  <Label>Pool</Label>
                                  <Total>{items.poolId + 1}</Total>
                                </Details>
                                <Details>
                                  <Label>TotalLock</Label>
                                  <Total>
                                    $
                                    <CountUp
                                      start={0}
                                      preserveValue
                                      delay={0}
                                      end={items.totalLock}
                                      decimals={items.totalLock > 0 ? 2 : 0}
                                      duration={0.5}
                                      separator=","
                                    />
                                  </Total>
                                </Details>
                                <Details>
                                  <Label>StartTime</Label>
                                  <Total>{moment.unix(items.startTime).format('DD-MM-YYYY')}</Total>
                                </Details>
                                <Link to={`${poolBaseUrlV2}/${items.poolId}/chainId=${CHAIN_ID}/${inputValue}`}>
                                  {isMobile ? (
                                    <Button
                                      style={{
                                        color: 'rgba(255, 255, 255, 1)',
                                        backgroundColor: '#141217',
                                        border: '1px solid #FFF',
                                        marginTop: '20px',
                                        borderRadius: '10px',
                                      }}
                                      variant="primary"
                                      width={'100px'}
                                      scale="md"
                                    >
                                      Detail
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        color: 'rgba(255, 255, 255, 1)',
                                        backgroundColor: '#141217',
                                        border: '1px solid #FFF',
                                        borderRadius: '10px',
                                        marginTop: '20px',
                                      }}
                                      variant="primary"
                                      width={'100px'}
                                      scale="md"
                                    >
                                      Detail
                                    </Button>
                                  )}
                                </Link>
                              </PoolInfo>
                            ))}
                          </div>
                        </Ver2>
                      )}
                    </>
                  )}
                </UserTotalLock>
              </UserInfo>
            )}
          </>
        )}
      </Container>
    </Wrapper>
  )
}
export default DetailReview
