import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input } from '@pancakeswap/uikit'
import { useState, useEffect } from 'react'
import { getContract } from 'utils/contractHelpers'
import addresses from 'config/constants/contracts'
import refferalAbi from 'config/abi/refferal.json'
import { useDispatch } from 'react-redux'
import { setRefLink } from 'state/referral'
import { useSigner } from 'wagmi'
import { InjectedProps } from '@pancakeswap/uikit/src/widgets/Modal/types'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import styled from 'styled-components'
import { ToastDescriptionWithTx } from 'components/Toast'
import useRegisterConfirmTransaction from 'hooks/useRegisterConfirmTransaction'
import { useTranslation } from '@pancakeswap/localization'
import TrendyPageLoader from 'components/Loader/TrendyPageLoader'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import md5 from 'md5-hash'
interface RegistersModalProps extends InjectedProps {}

const StyledInput = styled(Input)`
  outline: none;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  &:focus:not(:disabled) {
    border: none;
  }
  padding: 12px 16px;
`
const Title = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`
const TextLabel = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(226, 225, 229, 1);
  text-align: center;
`
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const ModalCheckPinCode = ({ onDismiss, onCheck }: { onCheck: () => void; onDismiss: () => void }) => {
  const [loading, setLoading] = useState(false)
  const { search } = window.location
  const { account, chainId } = useActiveWeb3React()

  const { data: signer } = useSigner()
  const [referCode, setReferCode] = useState('')
  const [showError, setShowError] = useState(false)
  const [pinCode, setPinCode] = useState('')
  const [pinCodeHash, setPinCodeHash] = useState('')
  // const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId;
  const protectAccount = process.env.NEXT_PUBLIC_DEFAULT_PROTECT_ACCOUNT
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)
  const refferCT = getContract({ address: addresses.refferal[CHAIN_ID], abi: refferalAbi, chainId: CHAIN_ID, signer })
  const getPin = () => {
    if (protectAccount.includes(account)) {
      const pin1 = md5(account)
      const pin2 = md5(pin1)
      const pin3 = md5(pin2)
      setPinCodeHash(pin3)
    }
  }
  const checkPin = () => {
    if (pinCode === pinCodeHash.slice(-8)) {
      onCheck()
      onDismiss()
    } else {
      setShowError(true)
    }
  }
  const handleChange = (e) => {
    setShowError(false)
    setPinCode(e)
  }
  useEffect(() => {
    getPin()
  }, [account])
  return (
    <>
      {' '}
      {loading === true ? (
        <TrendyPageLoader />
      ) : (
        <Modal style={{ width: '90%', maxWidth: '434px', padding: '32px' }} title="" onDismiss={onDismiss}>
          <ModalContainer>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              <Title>Verification</Title>
              <TextLabel>You need a pin code to continue transactions</TextLabel>
            </div>
            <div>
              <Text fontSize="12px" fontWeight="500" lineHeight="18px" marginBottom="12px">
                Pin code
              </Text>
              <StyledInput
                value={pinCode}
                autoFocus={true}
                placeholder={`pin code`}
                onChange={(e) => handleChange(e.target.value)}
              />
            </div>
            {showError && pinCode && <span style={{ color: 'red' }}>Invalid pin code</span>}
            <Button
              onClick={checkPin}
              style={{
                borderRadius: '8px',
                background: '#8544F5',
                boxShadow: '2px 2px 8px 16px rgba(0, 0, 0, 0.10)',
                color: 'rgba(255, 255, 255, 1)',
              }}
              disabled={pinCode === '' || showError}
            >
              Confirm
            </Button>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}
