import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, Text, useMatchBreakpoints, useModal, useToast } from '@pancakeswap/uikit'
import { useWeb3React } from '../../../packages/wagmi/src/useWeb3React'
import { Link } from 'react-router-dom'
import { getAirdropContract, getReadContract } from 'utils/contractHelpers'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useAirdropContract } from 'hooks/useContract'
import { ToastDescriptionWithTx } from 'components/Toast'
import { ThreeDots } from 'views/PoolV2/components/PoolDetailsV2/DepositModal'
import { formatEther } from '@ethersproject/units'
import CountUp from 'react-countup'
import { ModalCheckPinCode } from 'components/ModalPin'

const Waraper = styled.div`
  display: flex;
  justify-content: center;
  * {
    font-family: Inter, sans-serif;
  }
`
const CheckList = styled.div`
* {
    font-family: Inter,sans-serif;
}
background-image: linear-gradient(#18171b, #18171b), radial-gradient(circle at top left, #7b3fe4 0%, #a726c1 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  border: 1px solid transparent;
  border-image-slice: 1;
  position: relative;
max-width: 600px;
width: 100%;
padding: 20px 20px;
border-radius: 24px;
display: flex;
flex-direction: column;
justify-contect: center;
align-items: center;
gap: 20px;
@media screen and (max-width: 575px) {
    .text {
        font-size: 18px;
    }`
const Title = styled(Text)`
text-align: center;
color: #FFF;
text-align: center;

/* Display xs/Bold */
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */


}`
const ButtonClaim = styled(Button)`
  border-radius: var(--border-radius-lg, 8px);
  background: var(--primary-primary-1, #8544f5);
  color: #fff;
  /* light effect/boxShadow */
  box-shadow: 2px 2px 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-8, 8px);
  &:active:not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    transform: none;
  }
`
const SocialGroup = styled.div`
  display: flex;
  gap: 20px;
`
const Social = styled.a`
  img {
    width: 32px;
    height: 32px;
  }
`
const Container = styled.div`
  padding: 96px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  .system {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  // max-width: 876px;
  width: 100%;
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
  }
`
const CardGruop = styled.div`
  display: flex;
  gap: 26px;
  width: 100%;
  justify-content: center;
  @media screen and (max-width: 575px) {
    gap: 10px;
  }
`
const Card = styled.div`
  border-radius: 22px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
  max-width: 199px;
  height: 199px;
  width: 20%;
  // height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 575px) {
    width: 78px;
    height: 111px;
    border-radius: 8px;
  }
`
const CardText = styled(Text)`
  color: var(--greyscale-text, #e2e1e5);
  text-align: center;

  /* Display xs/Semibold */
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
`
const CardNumber = styled(Text)`
  color: var(--white-white, #fff);
  text-align: center;

  /* Display xl/Bold */
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 120% */
  letter-spacing: -1.2px;
  @media screen and (max-width: 800px) {
    font-size: 40px;
  }
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const AirDrops = () => {
  const { account, chainId } = useWeb3React()
  // const isCheckList = useRef(false)
  // account = '0x1ec0f8875B7fc2400a6F44788c6710959614e68A'
  const [isCheckList, setIsChecList] = useState(false)
  const [isClaim, setIsClaim] = useState(false)
  const [claimed, setClaimed] = useState(0)
  const [currentReward, setCurrentReward] = useState(0)
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const timeStamp = Math.floor(Date.now() / 1000)
  const [isDeposit, setIsDeposit] = useState(false)
  const [rewardTREND, setRewardTREND] = useState(0)
  const getAirdropContarct = getAirdropContract(chainId)
  const airdropContract = useAirdropContract()
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const { isMobile } = useMatchBreakpoints()
  const { toastSuccess } = useToast()
  const [available, setAvailable] = useState({
    total: 0,
    show: 0,
  })
  const isWhiteList = async () => {
    const isWL = await getAirdropContarct.isWL(account)
    const isClaimed = await getAirdropContarct.isClaimed(account)
    const totalTREND = await getAirdropContarct.usersMine(account)
    const checkCurrent = await getAirdropContarct.currentRewardTREND(account)
    setCurrentReward(Number(formatEther(checkCurrent)))
    // await getAvailable()

    setClaimed(Number(formatEther(totalTREND.claimed)))
    setRewardTREND(30 - Number(formatEther(totalTREND.claimed)))
    setIsChecList(isWL)
    setIsClaim(isClaimed)
  }
  // const getAvailable = async () => {
  //   const checkCurrent = await getAirdropContarct.currentRewardTREND(account)
  //   setCurrentReward(Number(formatEther(checkCurrent)))
  //   console.log(account)
  // }
  // const initAvailable = async () => {
  //   const checkCurrent = await getAirdropContarct.currentRewardTREND(account)
  //   const total = Number(formatEther(checkCurrent))
  //   const avai = {
  //     total,
  //     show: total,
  //   }
  //   if (available.total === 0) updateAvailable(avai)
  //   await setAvailable(avai)
  // }
  // const getAvailable = () => {
  //   if (!account) {
  //     setAvailable({
  //       total: 0,
  //       show: 0,
  //     })
  //   } else {
  //     initAvailable()
  //     setTimeout(async () => {
  //       initAvailable()
  //     }, 30000)
  //   }
  // }
  // const updateAvailable = (avai) => {
  //   const newAvai = {
  //     ...avai,
  //     show: avai.show + ((avai.total - avai.show) * 10) / 100,
  //   }
  //   setTimeout(async () => {
  //     if (avai.show > 0) await setAvailable(newAvai)
  //     updateAvailable(newAvai)
  //   }, 30000)
  // }

  useEffect(() => {
    isWhiteList()
    // let interval
    // if (account) interval = setInterval(() => getAvailable(), 30000)
    // return () => clearInterval(interval)
  }, [account])
  const onSuccess = () => {
    isWhiteList()
  }
  const [openModal, onDismiss] = useModal(
    <ModalCheckPinCode onDismiss={() => onDismiss()} onCheck={() => onCheck()} />,
    false,
    false,
    'ModalCheckPinCode',
  )

  const onCheck = () => {
    handleConfirm()
  }
  const handleCheckPin = () => {
    const protectAccount = process.env.NEXT_PUBLIC_DEFAULT_PROTECT_ACCOUNT
    if (protectAccount.includes(account)) {
      openModal()
    } else {
      handleConfirm()
    }
  }
  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      if (isClaim === false) {
        return callWithMarketGasPrice(airdropContract, 'claim')
      }
      return callWithMarketGasPrice(airdropContract, 'claimAfterDeposit')
    },
    onSuccess: async ({ receipt }) => {
      toastSuccess('Claim successfully !', <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onSuccess()
    },
  })

  // useEffect(() => {
  //   let countdown = 1694501880 - timeStamp
  //   const updateCountdown = () => {
  //     setDays(Math.floor(countdown / 86400))
  //     setHours(Math.floor((countdown % 86400) / 3600))
  //     setMinutes(Math.floor((countdown % 3600) / 60))
  //     setSeconds(countdown % 60)
  //   }
  //   const timer = setInterval(() => {
  //     if (countdown > 0) {
  //       updateCountdown()
  //       countdown--
  //     } else {
  //       clearInterval(timer)
  //     }
  //   }, 1000)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [timeStamp])
  const link = `/pool-details-v2/0/chainId=${chainId}`
  return (
    <Waraper>
      <Container>
        <>
          <img width="148px" height="120px" src="/images/V3/Error.svg" />

          {isCheckList === false ? (
            <CheckList>
              <Title className="text">
                Your account is not currently on the whitelist. To claim TREND, please consider following our social
                channels or joining the MATIC staking pool.
              </Title>
              <SocialGroup>
                <Social target="_blank" href="https://twitter.com/TrendyDefi">
                  <img src="/images/V3/twitter-icon.svg" />
                </Social>
                <Social target="_blank" href="https://t.me/trendydefi">
                  <img src="/images/V3/Telegramicon.svg" />
                </Social>
                <Social target="_blank" href="https://t.me/trendydefiglobal">
                  <img src="/images/V3/Telegramicon.svg" />
                </Social>
              </SocialGroup>
              <Link to="/pools">
                <ButtonClaim>Staking Now</ButtonClaim>
              </Link>
            </CheckList>
          ) : (
            <CheckList>
              {isClaim === false ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Title>Your Rewards:</Title>
                    <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                    <Title>0.3</Title>
                  </div>

                  <ButtonClaim onClick={handleCheckPin}>
                    {isConfirming ? (
                      <ThreeDots className="loading">
                        Claiming<span>.</span>
                        <span>.</span>
                        <span>.</span>
                      </ThreeDots>
                    ) : (
                      'Claim'
                    )}
                  </ButtonClaim>
                </>
              ) : (
                <>
                  {currentReward > 0 ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Title>Your Rewards:</Title>
                        <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                        <Title>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={rewardTREND}
                            decimals={rewardTREND > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Title>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Title>Available:</Title>
                        <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                        <Title>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={currentReward}
                            decimals={currentReward > 0 ? 6 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Title>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Title>Claimed:</Title>
                        <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                        <Title>
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={claimed}
                            decimals={claimed > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Title>
                      </div>
                      <ButtonClaim onClick={handleConfirm}>
                        {isConfirming ? (
                          <ThreeDots className="loading">
                            Claiming<span>.</span>
                            <span>.</span>
                            <span>.</span>
                          </ThreeDots>
                        ) : (
                          'Claim'
                        )}
                      </ButtonClaim>
                    </>
                  ) : (
                    <>
                      <Title style={{ fontSize: isMobile ? '20px' : '20px' }}>
                        Claimed Successfully. To receive $TREND daily, consider joining the &#8243;Experience&#8243;
                        investment package with a minimum stake of $100.
                      </Title>
                      <Link to={link}>
                        <ButtonClaim>Staking Now</ButtonClaim>
                      </Link>
                    </>
                  )}
                </>
                // <>
                //   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                //     <Title>Your Rewards:</Title>
                //     <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                //     <Title>
                //       <CountUp
                //         start={0}
                //         preserveValue
                //         delay={0}
                //         end={rewardTREND}
                //         decimals={rewardTREND > 0 ? 6 : 0}
                //         duration={0.5}
                //         separator=","
                //       />
                //     </Title>
                //   </div>
                //   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                //     <Title>Available:</Title>
                //     <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                //     <Title>
                //       <CountUp
                //         start={0}
                //         preserveValue
                //         delay={0}
                //         end={currentReward}
                //         decimals={currentReward > 0 ? 6 : 0}
                //         duration={0.5}
                //         separator=","
                //       />
                //     </Title>
                //   </div>
                //   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                //     <Title>Claimed:</Title>
                //     <img width="24px" height="24px" src="images/trendyloopcoin2.png" />
                //     <Title>
                //       <CountUp
                //         start={0}
                //         preserveValue
                //         delay={0}
                //         end={claimed}
                //         decimals={claimed > 0 ? 4 : 0}
                //         duration={0.5}
                //         separator=","
                //       />
                //     </Title>
                //   </div>

                //   <ButtonClaim disabled={currentReward === 0} onClick={handleConfirm}>
                //     {isConfirming ? (
                //       <ThreeDots className="loading">
                //         Claiming<span>.</span>
                //         <span>.</span>
                //         <span>.</span>
                //       </ThreeDots>
                //     ) : (
                //       'Claim'
                //     )}
                //   </ButtonClaim>
                // </>
              )}
            </CheckList>
          )}
        </>

        {/* <Text
          style={{
            fontSize: '20px',
            fontWeight: '500',
            color: '#E2E1E5',
          }}
        >
          Available in
        </Text>
        <CardGruop>
          <Card>
            <CardNumber>{days}</CardNumber>
            <CardText>DAYS</CardText>
          </Card>
          <Card>
            <CardNumber>{hours}</CardNumber>
            <CardText>HOURS</CardText>
          </Card>
          <Card>
            <CardNumber>{minutes}</CardNumber>
            <CardText>MINUTES</CardText>
          </Card>
          <Card>
            <CardNumber>{seconds}</CardNumber>
            <CardText>SECONDS</CardText>
          </Card>
        </CardGruop>
        <Text className="system">System Updating</Text>
        <Text
          style={{
            color: '#ADABB2',
            textAlign: 'center',
            maxWidth: '532px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '28px',
          }}
        >
          A new system update is available. This update includes security fixes, performance improvements, and new
          features.
        </Text> */}
      </Container>
    </Waraper>
  )
}
export default AirDrops
