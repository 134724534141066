import { Heading, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import { ThreeDots } from 'views/Pool/components/DepositModal'
import Child from '../child'
import truncateHash from '@pancakeswap/utils/truncateHash'

const CardReferral = styled.div`
  max-width: 1220px;
  min-height: 300px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;

  color: #e6e6e6;
  @media screen and (max-width: 1440px) {
    width: 75%;
  }
  @media screen and (max-width: 900px) {
    padding: 0 16px;
    width: 100%;
  }
  @media screen and (max-width: 575px) {
    padding: 0 16px;
    width: 100%;
  }
`
const FriendsList = styled.div`
  border-radius: 16px;
  background: var(--greyscale-grayscale-3, #141217);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  margin-top: 48px;
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
    margin-top: 28px;
  }
`
const CardFriends = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid var(--white-white-12, rgba(255, 255, 255, 0.12));
  background: var(--white-white-6, rgba(255, 255, 255, 0.06));
  backdrop-filter: blur(6px);
  width: 257px;
  @media screen and (max-width: 575px) {
    padding: 10px;
  }
`
const CardInfoUser = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;
  @media screen and (max-width: 575px) {
    padding: 0 16px;
  }
`

const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 125% */
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const GroupChangePage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
`
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  @media screen and (max-width: 1440px) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    gap: 16px;
  }
`
const StyledItemChild = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(173, 171, 178, 1);
`
const StyledTotal = styled(Text)`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: rgba(133, 68, 245, 1);
`
const StyledLinkAccount = styled.a`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: rgba(133, 68, 245, 1);
`
const Table = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  tr {
    width: 100%;
  }
  th {
    width: 251px;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: rgba(119, 126, 144, 1);
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 575px) {
    padding: 0px;
  }
`

const ChildItem = styled.tr`
  word-break: break-all;
  width: 100%;
  td {
    width: 251px;
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
  }
  @media screen and (max-width: 575px) {
    td {
      font-size: 14px;
    }
  }
`
const PageDashboardV1 = ({
  loadingTable,
  acountChild,
  totalItemChild,
  total7Level,
  listChild,
  handleChangeChild,
  handleBack,
  getButtonChangePage,
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints()

  return (
    <CardReferral>
      <StyledHead>Referral Dashboard V2.0</StyledHead>
      <StyledSubtitle>
        Here, you can easily track the growth of your referral downline, monitor your direct staked amount, view a list
        of wallet addresses, and stay updated on the volume and token locked within the system.{' '}
      </StyledSubtitle>
      <FriendsList>
        {loadingTable ? (
          <ThreeDots style={{ textAlign: 'center' }} className="loading">
            Loading
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </ThreeDots>
        ) : (
          <>
            <Group>
              <CardFriends style={{ width: isMobile ? '100%' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>F{acountChild.length - 1}:</StyledItemChild>
                <StyledLinkAccount
                  rel="noreferrer"
                  target="_blank"
                  href={process.env.NEXT_PUBLIC_SCAN + `/address/${acountChild[acountChild.length - 1]}`}
                >
                  {truncateHash(acountChild[acountChild.length - 1], 16, 3)}
                </StyledLinkAccount>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '147px' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Total of F{acountChild.length}</StyledItemChild>
                <StyledTotal>{totalItemChild}</StyledTotal>
              </CardFriends>
              <CardFriends style={{ width: isMobile ? '147px' : isTablet ? '100%' : '300px' }}>
                <StyledItemChild>Total refer downline</StyledItemChild>
                <StyledTotal>{total7Level}</StyledTotal>
              </CardFriends>
            </Group>
            <Table>
              <tr>
                <th>Friends</th>
                <th>Volumn</th>
                <th>Locked</th>
              </tr>
              {listChild.map((item, index) => (
                <div key={index}>
                  <ChildItem key={index}>
                    <td>
                      <div
                        onClick={() => handleChangeChild(item.account)}
                        style={{
                          cursor: 'pointer',
                          color: item.child > 0 ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 1)',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                          fontSize: isMobile ? '14px' : '24px',
                          fontWeight: '400',
                        }}
                      >
                        {item.account.substring(0, 2)}...{item.account.substring(item.account.length - 4)}
                        {item.child > 0 && <img src="/images/referral/plus.png" style={{ fill: 'white' }} />}
                      </div>
                    </td>
                    <td>
                      $
                      <CountUp
                        style={{ color: 'rgba(255, 255, 255, 1)', fontSize: isMobile ? '14px' : '24px' }}
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={item.volume}
                        decimals={3}
                        duration={1}
                      />
                    </td>
                    <td>
                      <CountUp
                        separator=","
                        start={0}
                        preserveValue
                        delay={0}
                        end={item.locked}
                        decimals={3}
                        duration={1}
                      />
                    </td>
                  </ChildItem>
                  {item.showChild && (
                    <ChildItem key={index + '-' + index}>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <Child referBy={item.account} />
                      </td>
                    </ChildItem>
                  )}
                </div>
              ))}
            </Table>
          </>
        )}
        <GroupChangePage>
          {acountChild.length > 1 ? (
            <button type="button" onClick={handleBack} style={{ color: 'black' }}>
              Back
            </button>
          ) : null}
          {getButtonChangePage(2)}
        </GroupChangePage>
      </FriendsList>
    </CardReferral>
  )
}
export default PageDashboardV1
