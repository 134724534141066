import { Button, Heading, LinkExternal, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React, { useEffect, useRef, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import { getBlockExploreLink } from 'utils'
import { shortenURL } from 'views/PoolV2/util'
import { formatEther } from '@ethersproject/units'
import { getPoolsContract, getPoolsV2Contract, getPoolsV4Contract } from 'utils/contractHelpers'
import moment from 'moment'
import { useWeb3React } from '../../../../packages/wagmi/src/useWeb3React'
import axios from 'axios'
import LoadingTable from 'views/LimitOrders/components/LimitOrderTable/LoadingTable'
import { ThreeDots } from 'views/PoolV2/components/PoolDetailsV2/DepositModal'
const PageTopRank = styled.div`
  width: 100%;
  * {
    font-family: Inter, sans-serif;
  }
  padding: 0 20px;
`
const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 125% */
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const Tab = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  width: 100%;
  .active {
    background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  margin-top: 20px;
`
const TabTitle = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: 575px) {
    font-size: 18px;
  }
`
const Tab1 = styled.div`
  border: 1px solid;
  --tw-border-opacity: 1;
  border-color: rgb(47 46 95 / var(--tw-border-opacity));
  border-radius: 25px;
  --tw-bg-opacity: 1;
  background-color: rgb(37 36 78 / var(--tw-bg-opacity));
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  .text {
    --tw-text-opacity: 1;
    color: rgb(110 111 166 / var(--tw-text-opacity));
  }
  .loading {
    font-size: 24px;
    color: white;
    font-weight: 700;
  }
  overflow-x: auto;
`
const Tab2 = styled.div`
  border: 1px solid;
  --tw-border-opacity: 1;
  border-color: rgb(47 46 95 / var(--tw-border-opacity));
  border-radius: 25px;
  --tw-bg-opacity: 1;
  background-color: rgb(37 36 78 / var(--tw-bg-opacity));
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  .text {
    --tw-text-opacity: 1;
    color: rgb(110 111 166 / var(--tw-text-opacity));
  }
  overflow-x: auto;
`
const STT = styled(Text)`
  color: white;
  font-size: 18px;
  margin: 10px 0;
  color: white;
  font-weight: 700;
  width: 50px;
`
const PreviousPage = styled.button`
  border-radius: 8px;
  cursor: pointer;
`
const NextPage = styled.button`
  border-radius: 8px;
  cursor: pointer;
`

const PageGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  .active {
    color: white;
    background: rgb(133, 68, 245);
  }
`
const Page = styled.button`
  border-radius: 30px;
  width: 26px;
  height: 26px;
  cursor: pointer;
`
const TableData = styled.div`
  padding: 0 20px;
  .account {
    --tw-text-opacity: 1;
    color: rgb(2 208 200 / var(--tw-text-opacity));
  }
  .colorBackground {
    border-radius: 12px;
    --tw-bg-opacity: 1;
    background-color: rgb(32 31 72 / var(--tw-bg-opacity));
  }
`
const TopRank = ({ top10Refer }) => {
  const { account } = useWeb3React()
  const [activeTab1, setActiveTab1] = useState(true)
  const [activeTab2, setActiveTab2] = useState(false)
  const [dataTable, setDataTable] = useState([])
  const dataSort = useRef([])
  const currentPage = useRef(1)
  const lastPage = useRef(10)
  const { isMobile } = useMatchBreakpoints()
  const CHAIN_ID = Number(process.env.NEXT_PUBLIC_DEFAULT_CHAIN)

  var resurtData = []
  const getListUsers = async () => {
    axios.get(`${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/topRank`).then((response) => {
      setDataTable(response.data.data)
    })
    getSortData()
  }
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(dataTable.length / 10); i++) {
    pageNumbers.push(i)
  }
  const lastPageIndex = lastPage.current * currentPage.current
  const handleChangePage = async (r) => {
    currentPage.current = r
    getSortData()
  }
  const getSortData = () => {
    var resurt = []
    if (activeTab1 === true) {
      const sortTotalReferr = dataTable.sort(function (a, b) {
        return b.totalRefer - a.totalRefer
      })
      resurt = sortTotalReferr.slice(lastPageIndex - lastPage.current, lastPageIndex)
    } else if (activeTab1 === false) {
      const sortVolume = dataTable.sort(function (a, b) {
        return b.volume - a.volume
      })
      resurt = sortVolume.slice(lastPageIndex - lastPage.current, lastPageIndex)
    }
    return resurt
  }

  const handleActiveTab = async () => {
    setActiveTab1(!activeTab1)
    setActiveTab2(!activeTab2)
    currentPage.current = 1
    getSortData()
  }
  useEffect(() => {
    getListUsers()
  }, [account])
  const getColor = (index) => {
    var color = ''
    switch (index) {
      case 1:
        color = '#55acee'
        break
      case 2:
        color = '#99FFFF'
        break
      case 3:
        color = '#FFFF00'
        break
      case 4:
        color = '#6600FF'
        break
      case 5:
        color = '#CC3333'
        break
      default:
        color = 'white'
    }
    return color
  }
  return (
    <>
      <PageTopRank>
        <StyledHead>Top Ranking</StyledHead>
        <Tab>
          <div style={{ cursor: 'pointer' }} onClick={() => handleActiveTab()}>
            <TabTitle className={activeTab1 === true && 'active'}>Top Rank Referral</TabTitle>
          </div>
          <div style={{ cursor: 'pointer' }} onClick={() => handleActiveTab()}>
            <TabTitle className={activeTab2 === true && 'active'}>Top Rank Volume</TabTitle>
          </div>
        </Tab>
        {activeTab1 === true ? (
          <Tab1>
            <div
              style={{
                display: 'flex',
                gap: '30px',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderBottom: '2px solid ',
                borderColor: 'rgb(47 46 95  / 1)',
                maxWidth: '1024px',
                width: isMobile ? '600px' : '100%',
              }}
            >
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  Rank
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  Users
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  ReferBy
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  TotalRefer
                </Text>
              </div>
            </div>
            {dataTable.length === 0 ? (
              <ThreeDots style={{ textAlign: 'center' }} className="loading">
                Loading
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </ThreeDots>
            ) : (
              <>
                <TableData style={{ maxWidth: '1024px', width: isMobile ? '520px' : '100%' }}>
                  {getSortData().map((items, index) => (
                    <div
                      className={index % 2 === 0 ? '' : 'colorBackground'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: isMobile ? 'space-between' : 'space-around',
                      }}
                    >
                      <STT style={{ color: getColor(currentPage.current) }} className="text">
                        {index + 1 + (currentPage.current - 1) * lastPage.current}.
                      </STT>
                      <Text
                        style={{ margin: '10px 0', width: '160px', display: 'flex', justifyContent: 'center' }}
                        ellipsis={true}
                        lineHeight="1.5"
                      >
                        <LinkExternal
                          fontSize={['14px', '14px', '16px', '18px', '18px']}
                          href={getBlockExploreLink(items.users, 'address', CHAIN_ID)}
                          ellipsis={true}
                          color="#00F0E1"
                          className="account"
                        >
                          {shortenURL(`${items.users}`, 14)}
                        </LinkExternal>
                      </Text>
                      <Text
                        style={{
                          margin: '10px 0',
                          width: isMobile ? '160px' : '215px',
                          display: 'flex',
                          justifyContent: isMobile ? 'center' : 'flex-start',
                        }}
                        ellipsis={true}
                        lineHeight="1.5"
                      >
                        <LinkExternal
                          fontSize={['14px', '14px', '16px', '18px', '18px']}
                          href={getBlockExploreLink(items.referrBy, 'address', CHAIN_ID)}
                          ellipsis={true}
                          color="#00F0E1"
                          className="account"
                        >
                          {shortenURL(`${items.referrBy}`, 14)}
                        </LinkExternal>
                      </Text>

                      <Text style={{ textAlign: 'end', width: '30px' }} fontWeight="700" fontSize="18px">
                        {items.totalRefer}
                      </Text>
                    </div>
                  ))}
                </TableData>
                <PageGroup>
                  <PreviousPage
                    onClick={() => handleChangePage(currentPage.current - 1)}
                    disabled={currentPage.current === 1}
                  >
                    Previous
                  </PreviousPage>
                  {pageNumbers.map((i, r) => (
                    <Page className={r === currentPage.current - 1 && 'active'} onClick={() => handleChangePage(r + 1)}>
                      {i}
                    </Page>
                  ))}
                  <NextPage
                    disabled={currentPage.current === pageNumbers.length}
                    onClick={() => handleChangePage(currentPage.current + 1)}
                  >
                    Next
                  </NextPage>
                </PageGroup>
              </>
            )}
          </Tab1>
        ) : (
          <Tab2>
            <div
              style={{
                display: 'flex',
                gap: '30px',
                alignItems: 'center',
                justifyContent: 'space-around',
                borderBottom: '2px solid ',
                borderColor: 'rgb(47 46 95  / 1)',
                maxWidth: '1024px',
                width: isMobile ? '600px' : '100%',
              }}
            >
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  Rank
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  Users
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  ReferBy
                </Text>
              </div>
              <div>
                <Text className="text" fontSize="24px" fontWeight="700">
                  Volume
                </Text>
              </div>
            </div>
            <TableData style={{ maxWidth: '1024px', width: isMobile ? '600px' : '100%' }}>
              {getSortData().map((items, index) => (
                <div
                  className={index % 2 === 0 ? '' : 'colorBackground'}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'space-between' : 'space-around',
                  }}
                >
                  <STT style={{ color: getColor(currentPage.current) }} className="text">
                    {index + 1 + (currentPage.current - 1) * lastPage.current}.
                  </STT>
                  <Text
                    style={{
                      margin: '10px 0',
                      width: isMobile ? '160px' : '200px',
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'flex-end',
                    }}
                    ellipsis={true}
                    lineHeight="1.5"
                  >
                    <LinkExternal
                      fontSize={['14px', '14px', '16px', '18px', '18px']}
                      href={getBlockExploreLink(items.users, 'address', CHAIN_ID)}
                      ellipsis={true}
                      color="#00F0E1"
                      className="account"
                    >
                      {shortenURL(`${items.users}`, 14)}
                    </LinkExternal>
                  </Text>
                  <Text
                    style={{
                      margin: '10px 0',
                      width: isMobile ? '160px' : '200px',
                      display: 'flex',
                      justifyContent: isMobile ? 'center' : 'flex-end',
                    }}
                    ellipsis={true}
                    lineHeight="1.5"
                  >
                    <LinkExternal
                      fontSize={['14px', '14px', '16px', '18px', '18px']}
                      href={getBlockExploreLink(items.referrBy, 'address', CHAIN_ID)}
                      ellipsis={true}
                      color="#00F0E1"
                      className="account"
                    >
                      {shortenURL(`${items.referrBy}`, 14)}
                    </LinkExternal>
                  </Text>

                  <Text style={{ width: '150px', textAlign: 'end' }} fontWeight="700" fontSize="18px">
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={items.volume}
                      decimals={items.volume > 0 ? 2 : 0}
                      duration={0.5}
                      separator=","
                    />
                  </Text>
                </div>
              ))}
            </TableData>
            <PageGroup>
              <PreviousPage
                onClick={() => handleChangePage(currentPage.current - 1)}
                disabled={currentPage.current === 1}
              >
                Previous
              </PreviousPage>
              {pageNumbers.map((i, r) => (
                <Page className={r === currentPage.current - 1 && 'active'} onClick={() => handleChangePage(r + 1)}>
                  {i}
                </Page>
              ))}
              <NextPage
                disabled={currentPage.current === pageNumbers.length}
                onClick={() => handleChangePage(currentPage.current + 1)}
              >
                Next
              </NextPage>
            </PageGroup>
          </Tab2>
        )}
      </PageTopRank>
    </>
  )
}
export default TopRank
