export const BSC_RPC_URLS = [
  'https://bsc-dataseed1.ninicoin.io',
  'https://bsc-dataseed1.defibit.io',
  'https://bsc-dataseed.binance.org',
]

export const BSC_TESTNET_RPC_URLS = [
  'https://data-seed-prebsc-1-s1.binance.org:8545',
  'https://data-seed-prebsc-2-s1.binance.org:8545',
  'https://data-seed-prebsc-1-s3.binance.org:8545',
]
export const MATIC_RPC_URL = [process.env.NEXT_PUBLIC_RPC || 'https://polygon-rpc.com']
