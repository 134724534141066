const images = {
  insurance1: '/images/homepage/Insurance1.png',
  arrowDown: '/images/homepage/arrow-down-circle.png',
  wallet: '/images/homepage/wallet.png',
  bgdao: '/images/dao/bgdao.svg',
  crypto: '/images/homepage/crypto.png',
  bgTrendy: '/images/homepage/bg-trendy.png',
  trans1: '/images/homepage/trans1.svg',
  trans2: '/images/homepage/trans2.svg',
  trans3: '/images/homepage/trans3.svg',
  vs: '/images/homepage/vs.svg',
  roadmap: '/images/homepage/roadmap.svg',
  roadmobile: '/images/homepage/roadmobile.svg',
  circle: '/images/homepage/circle.svg',
  key2: '/images/homepage/key.png',
  bookOpen: '/images/homepage/bookopen.png',
  lock2: '/images/homepage/lock.png',
  person: '/images/homepage/person.png',
  galaxy: '/images/homepage/galaxy.png',
  star: '/images/homepage/star.png',
  coinHead: '/images/homepage/asf.png',
  phone: '/images/homepage/phone.png',
  downloadIOS: '/images/homepage/download_apple.png',
  downloadAPK: '/images/homepage/download_apk.png',
  downloadPlay: '/images/homepage/download_play.png',
  android: '/images/homepage/android.png',
  apple: '/images/homepage/apple.png',
  chPlay: '/images/homepage/ch-play.png',
  token: '../images/homepage/token.png',
  insurance: 'images/homepage/insurance.png',
  createwallet: 'images/homepage/createwallet.png',
  getcrypto: 'images/homepage/getcrypto.png',
  downloadwallet: 'images/homepage/downloadwallet.png',
  key: 'images/homepage/key.png',
  authen: 'images/homepage/authen.png',
  lock: 'images/homepage/lock.png',
  ledger: 'images/homepage/ledger.png',
  cyptophone: 'images/homepage/cyptophone.png',
  check: 'images/homepage/check.png',
  bgcovered: 'images/homepage/bgcovered.png',
  bginsurance: 'images/homepage/bginsurance.png',
  bghead: 'images/homepage/bghead2.png',
  bgstep: 'images/homepage/bgstep1.png',
  twitter: '/images/homepage/Twitter.png',
  telegram: '/images/homepage/Telegram.png',
  medium: '/images/homepage/Medium.png',
  logo: '../images/homepage/logo.svg',
  trendyLogo: '../logo.svg',
  bgPools: 'images/pools/bgPools.svg',
  logoEthw: 'images/pools/ethw.svg',
  logoMatic: '../images/pools/matic.png',
  logoBusd: 'images/pools/busd.svg',
  logoBtc: 'images/pools/btc.svg',
  poolDetailFooterBg: '../images/pools/poolDetailFooterBg.png',
  backgroundpool: '../images/pools/bg.svg',
  backgroundpoolV2: '../images/backgroundMinning.png',
  mask: '../images/Maskgroup.png',
  iconEthw: 'images/coin/eth.svg',
  iconMatic: 'images/coin/matic.svg',
  chartIcon: 'images/homepage/chart-icon.png',
  col0: 'images/homepage/col0.png',
  col1u: 'images/homepage/xanh.gif',
  col1d: 'images/homepage/xanhback1.gif',
  col2u: 'images/homepage/tim1.gif',
  col2d: 'images/homepage/timback1.gif',
  col3u: 'images/homepage/cam.gif',
  col3d: 'images/homepage/camback.gif',
  col4u: 'images/homepage/hong.gif',
  col4d: 'images/homepage/hongback.gif',
  yellowBall: '/images/homepage/yellowBall.svg',
  redBall: '/images/homepage/redBall.svg',
  violetBall: '/images/homepage/violetBall.svg',
  iconBusd: '/images/coin/busd.svg',
  bgProfile: '/images/homepage/bgprofile.png',
  colbase: '/images/homepage/colbase.svg',
  chart1up: '/images/homepage/chart1up.gif',
  chart1down: '/images/homepage/chart1down.gif',
  chart2up: '/images/homepage/chart2up.gif',
  chart3up: '/images/homepage/chart3up.gif',
  chart4up: '/images/homepage/chart4up.gif',
  chart2down: '/images/homepage/chart2down.gif',
  chart3down: '/images/homepage/chart3down.gif',
  chart4down: '/images/homepage/chart4down.gif',
  headhome: '/images/V3/headhome.gif?t=1',
  step1: '/images/homepage/step1.png',
  step2: '/images/homepage/step2.png',
  step3: '/images/homepage/step3.png',
  security1: '/images/homepage/security1.png',
  security2: '/images/homepage/security2.png',
  security3: '/images/homepage/security3.png',
  iconpoolsV2: '/images/IconPoolsV2.svg',
  bg: '/images/BG.png',
  Illustrations: '/images/Illustrations.png',
  roadmapV3: '/images/V3/Roadmaps.svg',
  bgV3: '/images/V3/BG.png',
  iconback: '/images/V3/iconback.svg',
  bscoin: '/images/V3/bscoin.svg',
  vector: '/images/V3/Vector.svg',
  bronze: 'images/V3/bronzebg.png',
  homeMobile: '/images/V3/headhome.gif?t=1',
  checkk: 'images/V3/check.svg',
  iconNew: '/images/V3/newV3.jpg',
}

export default images
