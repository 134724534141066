import { Button, Heading, Input, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import truncateHash from '@pancakeswap/utils/truncateHash'
import CountUp from 'react-countup'
import moment from 'moment'

const ProfilePage = styled.div``
const Item = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
`
const StyledHead = styled(Heading)`
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px; /* 125% */
  letter-spacing: -0.96px;
  background: var(--primary-primary-gradient-2, linear-gradient(180deg, #7b3fe4 0%, #a726c1 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: 575px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const StyledSubtitle = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  color: rgba(173, 171, 178, 1);
  @media screen and (max-width: 575px) {
    font-size: 16px;
    margin-top: 16px;
    line-height: 24px;
  }
  .title {
    color: white;
    font-weight: 700;
  }
`
const CardRegister = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  height: auto;
  padding: 40px;
  border-radius: 16px;
  background: var(--greyscale-grayscale-3, #141217);
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  position: relative;
  .upto {
    position: absolute;
    top: -12%;
    right: -7%;
  }
  @media screen and (max-width: 575px) {
    padding: 40px 16px;
    .upto {
      position: relative;
      top: 0;
      right: 0;
      width: 343px;
    }
  }
`
const GroupLinkRef = styled.div`
  flex-direction: column;
  display: flex;
  gap: 48px;
  width: 100%;
`
const StyledText = styled(Text)`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: rgba(173, 171, 178, 1);
`
const WrapperLinkRef = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  padding: 32px;
  gap: 32px;
  @media screen and (max-width: 575px) {
    padding: 16px;
    width: 100%;
  }
`

const GroupLink = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`
const StyledLink = styled.div`
  width: 100%;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
  flex-direction: row-reverse;
  min-height: 34px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
  padding: 12px 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 44px;
  }
`
const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
`
const ShowLinkRefPc = styled.span`
  display: none;
  word-break: break-all;
  color: #fff;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`
const ShowLinkRefMobile = styled.span`
  display: block;
  word-break: break-all;
  color: #fff;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: none;
  }
`
const StyledIconRef = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const StyledInput = styled(Input)`
  outline: none;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;
  height: 44px;
  &:focus:not(:disabled) {
    border: none;
  }
`
const StyledButton = styled(Button)`
  background: rgb(217, 217, 217);
  font-weight: 500;
  color: rgb(98, 22, 176);
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 90px;
  width: 192px;
  border: 2px solid var(--greyscale-grey-scale-text-seconday, #adabb2);
  @media screen and (max-width: 575px) {
    margin-top: 0px;
  }
`
const GroupSearchByCode = styled.div`
  .search {
    position: absolute;
    top: 20%;
    width: 24px;
    height: 24px;
    right: 2%;
    cursor: pointer;
  }
`
const StyledHeadSearchUser = styled(Heading)`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 12px;
`
const StyledInputSearch = styled(Input)`
  outline: none;
  height: 48px;
  border-radius: 12px;
  background: var(--greyscale-grayscale-4, #2d2c33);
  padding: 12px 16px;

  &:focus:not(:disabled) {
    border: none;
  }
  ::placeholder {
    color: #67666e;
  }
`
const ButtonLink = styled.button`
  width: 192px;
  border-radius: 90px;
  border: 2px solid var(--greyscale-grey-scale-text-seconday, #adabb2);
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: rgba(255, 255, 255, 1);
  background: rgba(20, 18, 23, 1);
  cursor: pointer;
`
const Step = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`
const TitleStep = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
`
const LabelStep = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: rgba(173, 171, 178, 1);
`
const CardStep = styled.div`
  display: flex;
  margin-top: 24px;
  gap: 24px;
  justify-content: space-between;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`
const Card = styled.div`
  width: 340px;
  border-radius: 8px;
  background: var(--white-white-6, rgba(255, 255, 255, 0.06));
  padding: 29px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .imgStep {
    width: 48px;
    height: 48px;
  }
  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`
const CardTitle = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
`
const CardInfoUser = styled.div`
  width: 100%;
  height: auto;
  padding: 0 16px;
  @media screen and (max-width: 575px) {
    padding: 0 16px;
  }
`
const BlockInfo = styled.div`
display: flex;
padding: 40px;
flex-direction: column;
align-items: flex-start;
gap: 48px;
align-self: stretch;
  border-radius: 16px;
  margin-top: 48px;
background: var(--greyscale-grayscale-3, #141217);
/* depth/4 */
box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.10);
@media screen and (max-width: 575px ) {
  padding 40px 16px;
  margin-top: 28px;

}
`

const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  color: #e6e6e6;
  border-radius: 10px;
  max-width: 286px;
  gap: 12px;
  border: 1px solid var(--white-white-12, rgba(255, 255, 255, 0.12));
  background: var(--white-white-6, rgba(255, 255, 255, 0.06));
  backdrop-filter: blur(6px);
  padding: 16px 24px;
  @media screen and (max-width: 575px) {
    width: 145px;
    padding: 16px;
  }
`
const Label = styled(Text)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: rgba(119, 126, 144, 1);
`
const Value = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  color: rgba(133, 68, 245, 1);
`
const ReferralInfo = styled.div`
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  display: flex;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`
const InfoTitle = styled(Text)`
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`
const PageProfile = ({ userInfos }) => {
  return (
    <ProfilePage>
      <CardInfoUser>
        <StyledHead>Profile</StyledHead>
        <StyledSubtitle>*Note</StyledSubtitle>
        <StyledSubtitle>
          <span className="title">Total referrals:</span> The number of users signed up for Trendy DeFi using your
          referral link. <b />
        </StyledSubtitle>
        <StyledSubtitle>
          <span className="title">Refer Downline:</span> The group of users that you refers to either directly or
          indirectly through your referral link or code.
        </StyledSubtitle>
        <StyledSubtitle>
          <span className="title">Direct staked:</span> The tokens staked by users referred directly by your referral
          link or code are typically considered part of the referrer&#39;s downline at the first level{' '}
          <a
            style={{ color: 'rgba(133,68,245,1)' }}
            href="https://trendydefi.gitbook.io/trendy-defi/staking-and-refferal/benefits-for-referral"
          >
            (F1)
          </a>
        </StyledSubtitle>
        <StyledSubtitle>
          <span className="title">7-level staked:</span> The amount of rewards is based on the total number of
          referrals, up to a maximum of 10%. The rewards are divided into 7 levels.
        </StyledSubtitle>
        <BlockInfo>
          <Item>
            <InfoItem>
              <Label>Total referrals</Label>
              <Value>{userInfos.totalReffer}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Refer Downline</Label>
              <Value>{userInfos.totalRefer7}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Direct staked</Label>
              <Value>{userInfos.directStaked}</Value>
            </InfoItem>
            <InfoItem>
              <Label>7 level staked</Label>
              <Value>
                <CountUp
                  separator=","
                  start={0}
                  preserveValue
                  delay={0}
                  end={userInfos.totalStaked7}
                  decimals={3}
                  duration={1}
                />
                $
              </Value>
            </InfoItem>
          </Item>
          <div style={{ width: '100%' }}>
            <Label textAlign="right">Total commission</Label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                marginTop: '12px',
              }}
            >
              <Text fontSize="48px" fontWeight="600" lineHeight="60px" letterSpacing="-0.96px">
                <CountUp
                  separator=","
                  start={0}
                  preserveValue
                  delay={0}
                  end={userInfos.totalComms}
                  decimals={3}
                  duration={1}
                />
              </Text>
              <div
                style={{
                  background: 'var(--white-white-6, rgba(255, 255, 255, 0.06))',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(6px)',
                  borderRadius: '12px',
                  padding: '8px',
                  width: '60px',
                }}
              >
                <img width="40px" height="34px" src="./images/V3/Vector.png" />
              </div>
            </div>
          </div>
          <ReferralInfo>
            <div>
              <Label fontWeight="600">Referred by</Label>
              <InfoTitle>{truncateHash(userInfos.refferBy, 6, 4)}</InfoTitle>
            </div>
            <div>
              <Label fontWeight="600">Date</Label>
              <InfoTitle>{userInfos.date === 0 ? 0 : moment(Number(userInfos.date)).format('MMM Do YYYY')}</InfoTitle>
            </div>
          </ReferralInfo>
        </BlockInfo>
      </CardInfoUser>
    </ProfilePage>
  )
}
export default PageProfile
