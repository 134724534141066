import { Text } from '@pancakeswap/uikit'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'
import { ThreeDots } from 'views/PoolV2/components/PoolDetailsV2/DepositModal'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-itesm: center;
  justify-content: center;
  table {
    border: 1px solid #7b3fe4;
    border-radius: 16px;
  }
  tr {
    border-bottom: 1px solid #7b3fe4;
  }
  thead {
    background: rgba(45, 38, 51, 1);
  }
  th {
    color: #ffffff;
    font-size: 24px;
    padding: 10px;
  }
  td {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    padding: 20px;
  }
  caption {
    caption-side: top;
    padding: 10px;
    font-weight: bold;
    color: #7b3fe4;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
  }
`
const TableScroll = styled.div`
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  max-width: 1200px;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ccc;
  }

  @media screen and (max-width: 480px) {
    overflow-x: auto;
    width: 100%;
  }
  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`
const TablePool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    @media screen and (max-width: 575px) {
      width: 700px;
    }
  }
`
const Title = styled(Text)`
  color: #7b3fe4;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
`
const System = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [dataSystem, setDataSystem] = useState({
    totalClaimedV1USD: 0,
    totalLockV1USD: 0,
    totalRemainRewardV1USD: 0,
    totalRewardV1USD: 0,
  })
  const getData = async () => {
    try {
      setIsLoading(true)
      const data = await axios.get(`${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/system`)
      setDataSystem(data.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <Wrapper>
      {isLoading === true ? (
        <ThreeDots className="loading">
          Loading<span>.</span>
          <span>.</span>
          <span>.</span>
        </ThreeDots>
      ) : (
        <TablePool>
          <TableScroll>
            <table style={{ width: '100%' }}>
              <caption>System</caption>
              <thead>
                <tr>
                  <th scope="col">Version</th>
                  <th scope="col">TotalLock</th>
                  <th scope="col">TotalReward</th>
                  <th scope="col">Claimed</th>
                  <th scope="col">Remain</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Ver1</td>
                  <td>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={3}
                      duration={1}
                      end={Number(dataSystem.totalLockV1USD)}
                    />
                  </td>
                  <td>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={3}
                      duration={1}
                      end={Number(dataSystem.totalRewardV1USD)}
                    />
                  </td>
                  <td>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={3}
                      duration={1}
                      end={Number(dataSystem.totalClaimedV1USD)}
                    />
                  </td>
                  <td>
                    $
                    <CountUp
                      separator=","
                      start={0}
                      preserveValue
                      delay={0}
                      // end={Number(Number(balance) * rateBnbUsd)}
                      decimals={3}
                      duration={1}
                      end={Number(dataSystem.totalRemainRewardV1USD)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Ver2</td>
                  <td>---/---</td>
                  <td>---/---</td>
                  <td>---/---</td>
                  <td>---/---</td>
                </tr>
              </tbody>
            </table>
          </TableScroll>
        </TablePool>
      )}
    </Wrapper>
  )
}
export default System
