import { PropsWithChildren, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { trendyColors } from 'style/trendyTheme'
import { Table, Th, Td, Text, Button, useModal } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { getPoolsV3Contract, getPoolsV4Contract } from 'utils/contractHelpers'
import CountUp from 'react-countup'
import { Pool, PoolV4, timeDisplay } from 'views/PoolV2/util'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import moment from 'moment'
import { formatEther } from '@ethersproject/units'
import images from 'configs/images'
import { ChainId } from '../../../../../../packages/swap-sdk/src/constants'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'
import ProgressBar from '../../progressBar'
import useOnScreen from 'hooks/useOnScreen'
import numeral from 'numeral'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import ClaimPoolModal from '../ClaimModal'
import DepositPoolModal from '../DepositModal'
// STYLE
const TableScroll = styled.div`
  // max-width: 100%;
  width: auto;
  &.scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &.scroll::-webkit-scrollbar-track {
    background-color: white;
  }
  &.scroll::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: rgb(0, 240, 225);
  }
  @media screen and (max-width: 480px) {
    overflow-x: auto;
    width: 100%;
  }
  Th {
    padding: 20px 10px;
    @media screen and (max-width: 896px) {
      padding: 20px 25px;
    }
    @media screen and (max-width: 530px) {
      padding: 20px 16px;
    }
  }
  Td {
    border-bottom: 0;
    padding: 10px 20px;
    @media screen and (max-width: 730px) {
      padding: 10px 20px;
    }
  }
  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--white-white-8, rgba(255, 255, 255, 0.08));
  }
`

const TableHeader = styled.span`
  width: 100%;
  font-size: 26px;
  padding: 40px 0;
  display: flex;
  justify-content: start;
  @media (max-width: 575px) {
    padding: 30px 0;
  }
`
const TablePool = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--white-white-8, rgba(255, 255, 255, 0.08));
  background: var(--black-black-5, rgba(0, 0, 0, 0.05));
  table > thead {
    tr {
      font-weight: 300;
      border-bottom: 1px solid ${trendyColors.BLACK};
    }
  }

  @media screen and (max-width: 360px) {
    width: 100%;
  }
`
const AmountData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`
const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
const Body = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  background: none;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  @media (max-width: 575px) {
    padding: 32px 20px 70px;
  }
`
const responsiveTextTH = ['11px', '12px', '16px', '16px', '16px']
const responsiveTextSize = ['11px', '12px', '16px', '18px', '20px']
const responsiveTextSizeBNB = ['9px', '10px', '12px', '14px', '16px']
const responsiveTextSizeHeader = ['16px', '18px', '22px', '26px', '30px']
const TableDataPool: React.FC<
  PropsWithChildren<{
    accountUser: string
    pool: PoolV4
    userClaimedLength: number
    usersClaimed: any
    handleSuccess: any
  }>
> = ({ accountUser, pool, usersClaimed, userClaimedLength, handleSuccess, ...props }) => {
  const { account, chainId } = useActiveWeb3React()
  // account = '0x19024Aa592Ee64aB5908841F5a2Aa130BbE7B4c2'
  const total = pool.totalRewardUSD + pool.remainRewardUSD
  const amountClaim = total - pool.remainRewardUSD
  const amount = useRef('')
  const CHAIN_ID = chainId === undefined ? ChainId.BSC_TESTNET : chainId
  const getPoolContract = getPoolsV4Contract(CHAIN_ID)
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const [priceMaticUsd, setPriceMaticUsd] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [totalMaxout, setTotalMaxout] = useState(0)
  const getRemain = () => {
    var array = []
    const totalRemain = pool.totalRewardUSD + pool.remainRewardUSD
    array.push(totalRemain)
    amount.current = `$ ${numeral(totalRemain - pool.remainRewardUSD).format('0,0')} / $ ${numeral(totalRemain).format(
      '0,0',
    )}`
    for (let i = 0; i < usersClaimed.length; i++) {
      const arrRemain = Number(array[i]) - Number(usersClaimed[i].amountUSD)
      array.push(arrRemain)
    }
    return array
  }
  const [openClaimModal] = useModal(<ClaimPoolModal account={account} onSuccess={handleSuccess} pool={pool} />, true)
  const [openDepositModal] = useModal(
    <DepositPoolModal pool={pool} onSuccess={handleSuccess} account={account} chainId={chainId} />,
    true,
  )
  const handleOpenDepositModal = () => {
    // if (isRef === true) {
    //   openModalCheckRegisterModal()
    // } else openDepositModal()
    openDepositModal()
  }
  const getNewArray = () => {
    const remainArr = getRemain().slice(1)
    const arr = usersClaimed.map((i, r) => ({
      ...i,
      remain: remainArr[r],
    }))
    return arr
  }

  useEffect(() => {
    getRemain()
    getNewArray()
  }, [account])
  const renderClaimHistory = () => {
    return (
      <>
        {pool.userTotalLock > 0 && (
          <tr>
            <Td textAlign={'center'}>
              <Text fontSize={responsiveTextSize}>Total</Text>
            </Td>
            <Td textAlign={'center'}>
              <Text fontSize={responsiveTextSize}>
                {pool.isMine ? (
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={Number(pool.currentInterestWithMine)}
                    decimals={2}
                    duration={0.5}
                  ></CountUp>
                ) : (
                  <CountUp
                    start={0}
                    preserveValue
                    delay={0}
                    end={Number(pool.currentInterest)}
                    decimals={2}
                    duration={0.5}
                  ></CountUp>
                )}
              </Text>
            </Td>
            <Td textAlign={'right'}>
              {pool.userTotalLock === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    $
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.userTotalLockUSD}
                      decimals={pool.userTotalLock > 0 ? 4 : 0}
                      duration={0.5}
                      separator=","
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'right'}>
              {pool.totalRewardUSD + pool.remainRewardUSD === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    $
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.totalRewardUSD + pool.remainRewardUSD}
                      decimals={pool.totalRewardUSD + pool.remainRewardUSD > 0 ? 4 : 0}
                      duration={0.5}
                      separator=","
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'center'}>
              <AmountData>
                <Text
                  fontSize={responsiveTextSizeBNB}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  ----/----
                </Text>
              </AmountData>
            </Td>
            <Td textAlign={'right'}>
              {pool.currentReward === 0 ? (
                <Text fontSize={responsiveTextSize}>0</Text>
              ) : (
                <AmountData>
                  <Text fontSize={responsiveTextSize}>
                    ~
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.currentReward * pool.rateBNB2USD}
                      decimals={pool.currentReward > 0 ? 3 : 0}
                      duration={0.5}
                      separator=","
                      style={{ marginRight: 6 }}
                    />
                    $
                  </Text>
                  <Text
                    fontSize={responsiveTextSizeBNB}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                  >
                    ~
                    <CountUp
                      start={0}
                      preserveValue
                      delay={0}
                      end={pool.currentReward}
                      separator=","
                      decimals={pool.currentReward > 0 ? 4 : 0}
                      duration={0.5}
                    />
                    <img
                      className="imagesvector"
                      src={images.vector}
                      alt="pool name"
                      width={18}
                      style={{ marginLeft: 6 }}
                    />
                  </Text>
                </AmountData>
              )}
            </Td>
            <Td textAlign={'center'}>
              <Text fontSize={responsiveTextSize}>
                <span>{pool.currentReward <= 0 ? 'Waiting' : 'Claimable'}</span>
              </Text>
            </Td>
          </tr>
        )}
        {renderHistory()}
      </>
    )
  }
  const renderHistory = () => {
    return (
      <>
        {userClaimedLength > 0 &&
          getNewArray()
            .sort((a, b) => b.date - a.date)
            .map((claimHistory, index) => {
              return (
                <tr key={index}>
                  <Td textAlign={'right'}>
                    <Text fontSize={responsiveTextSize}>
                      {' '}
                      {moment.unix(claimHistory.date * 86400).format('DD-MM-YYYY')}
                    </Text>
                  </Td>
                  <Td textAlign={'right'}>
                    <Text fontSize={responsiveTextSize}> {claimHistory.interest}</Text>
                  </Td>
                  <Td textAlign={'right'}>
                    {claimHistory.totalLock === 0 ? (
                      <Text fontSize={responsiveTextSize}>0</Text>
                    ) : (
                      <AmountData>
                        <Text fontSize={responsiveTextSize}>
                          $
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={pool.userTotalLockUSD}
                            decimals={pool.totalLock > 0 ? 2 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Text>
                      </AmountData>
                    )}
                  </Td>
                  <Td textAlign={'right'}>
                    {pool.totalRewardUSD + pool.remainRewardUSD === 0 ? (
                      <Text fontSize={responsiveTextSize}>0</Text>
                    ) : (
                      <AmountData>
                        <Text
                          fontSize={responsiveTextSizeBNB}
                          style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                        >
                          $
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={pool.totalRewardUSD + pool.remainRewardUSD}
                            decimals={pool.totalRewardUSD + pool.remainRewardUSD > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Text>
                      </AmountData>
                    )}
                  </Td>
                  <Td textAlign={'right'}>
                    {pool.totalRewardUSD + pool.remainRewardUSD === 0 ? (
                      <Text fontSize={responsiveTextSize}>0</Text>
                    ) : (
                      <>
                        <AmountData>
                          <Text
                            fontSize={responsiveTextSizeBNB}
                            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                          >
                            $
                            <CountUp
                              start={0}
                              preserveValue
                              delay={0}
                              end={claimHistory.remain}
                              decimals={claimHistory.remain > 0 ? 4 : 0}
                              duration={0.5}
                              separator=","
                            />
                          </Text>
                        </AmountData>
                      </>
                    )}
                  </Td>
                  <Td textAlign={'right'}>
                    {claimHistory.amount === 0 ? (
                      <Text fontSize={responsiveTextSize}>0</Text>
                    ) : (
                      <AmountData>
                        <Text fontSize={responsiveTextSize}>
                          $
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={claimHistory.amountUSD}
                            decimals={claimHistory.amount > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                        </Text>
                        <Text fontSize={responsiveTextSizeBNB}>
                          ~
                          <CountUp
                            start={0}
                            preserveValue
                            delay={0}
                            end={claimHistory.amount}
                            decimals={claimHistory.amount > 0 ? 4 : 0}
                            duration={0.5}
                            separator=","
                          />
                          {pool.unit}
                        </Text>
                      </AmountData>
                    )}
                  </Td>
                  <Td textAlign={'center'}>
                    <Text fontSize={responsiveTextSize}>Claimed</Text>
                  </Td>
                </tr>
              )
            })}
      </>
    )
  }

  const progress = Math.round((amountClaim / total) * 100)
  return (
    <div ref={ref}>
      <TableHeader>
        <Text fontSize={responsiveTextSizeHeader}>Your Income </Text>
      </TableHeader>
      {/* {userClaimedLength > 0 && <ProgressBar title={amount.current} isVisible={isVisible} progress={progress} />} */}
      <ProgressBar title={amount.current} isVisible={isVisible} progress={progress} />
      <TablePool>
        <TableScroll className="scroll">
          <table>
            <thead>
              <tr>
                <Th textAlign="left">
                  <Text
                    className="th"
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Date Time
                  </Text>
                </Th>
                <Th textAlign="center">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Interest (%)
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Your Lock
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Total Reward
                  </Text>
                </Th>
                <Th>
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Remain
                  </Text>
                </Th>
                <Th textAlign="right">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Your Income
                  </Text>
                </Th>
                <Th textAlign="center">
                  <Text
                    style={{ color: '#777E90', fontWeight: '600' }}
                    color={trendyColors.COLORTABLE}
                    fontSize={responsiveTextTH}
                    textTransform="capitalize"
                  >
                    Action
                  </Text>
                </Th>
              </tr>
            </thead>
            <tbody>
              <>{renderClaimHistory()}</>
            </tbody>
          </table>
        </TableScroll>
      </TablePool>
      <Body>
        {!accountUser && (
          <ButtonArea>
            <Button
              style={{ color: '#000', backgroundColor: '#D9D9D9' }}
              variant={pool.currentReward > 0 ? 'danger' : 'light'}
              disabled={pool.currentReward === 0 || pool.remainRewardUSD === 0}
              width={['120px', '150px', '180px', '200px']}
              height={48}
              onClick={openClaimModal}
              scale={isMobile ? 'sm' : 'md'}
            >
              Claim
            </Button>
            <Button
              style={{ background: 'var(--primary-primary-1, #8544F5)', color: '#ffffff' }}
              width={['120px', '150px', '180px', '200px']}
              height={48}
              onClick={() => handleOpenDepositModal()}
              scale={isMobile ? 'sm' : 'md'}
              disabled={pool.userTotalLockUSD > 0}
            >
              Deposit
            </Button>
          </ButtonArea>
        )}
      </Body>
    </div>
  )
}
export default TableDataPool
