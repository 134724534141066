import styled from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import { Table } from 'antd'
import axios from 'axios'
import CountUp from 'react-countup'
import { Button, Td, Text, Th, useMatchBreakpoints } from '@pancakeswap/uikit'
import Image from 'next/image'
import images from 'configs/images'

const Wrapper = styled.div`
  padding: 0 40px;
  @media screen and (max-width: 575px) {
    padding: 0 20px;
  }
  table {
    border-radius: 20px;
    border: 1px solid transparent;
    border-image-slice: 1;
    background-image: linear-gradient(#18171b, #18171b), radial-gradient(circle at top left, #7b3fe4 0%, #a726c1 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
  thead {
    border-bottom: 2px solid #7b3fe4;
    tr {
      font-size: 24px;
      font-weight: 700;
      color: #ffffff;
      th {
        padding: 20px;
      }
    }
  }
  tbody {
    td {
      padding: 20px;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
    }
  }
`
const Title = styled(Text)`
  font-size: 36px;
  font-weight: 700;
  color: #7b3fe4;
`
const TableScroll = styled.div`
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #ccc;
  }

  @media screen and (max-width: 480px) {
    overflow-x: auto;
    width: 100%;
  }
  Td {
    border-bottom: 1px solid #444444;
    padding: 16px 24px;
    @media screen and (max-width: 575px) {
      padding: 10px !important;
    }
  }
  Th {
    padding: 16px 24px;
    border-bottom: 0;
    color: #777d90;
  }
  thead {
    background: rgba(45, 38, 51, 1);
  }
  .imagesvector {
    padding: 4px;
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

const TablePool = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    @media screen and (max-width: 575px) {
      width: 700px;
    }
  }
`
const AmountData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: 575px) {
    flex-direction: column;
    gap: 0px;
  }
`
const TextTh = styled.p`
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: #e2e1e5;
`
const TextData = styled(Text)`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  text-align: center;
  color: #e2e1e5;
`
const StyledPage = styled.div`
  width: 100%;
  height: 80px;
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(23, 19, 32, 0.5);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .active {
    background: #fff;
    color: #000;
  }
`
const BtnPage = styled(Button)`
  max-width: 136px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  background: none;
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  border: none;
  &:disabled {
    background: none !important;
    color: gray !important;
  }
  @media (max-width: 575px) {
    width: 120px;
    gap: 0;
    padding: 0 12px 0 0;
  }
`
const BtnPageNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  cursor: pointer;
  @media (max-width: 575px) {
    width: 28px;
    height: 28px;
  }
`
const StyledPageNumber = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
  @media (max-width: 350px) {
    display: none;
  }
`
const StyledTableHis = styled.div`
  max-width: 1280px;
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(23, 19, 32, 0.5);
`

const InputSearch = styled.input`
  width: 221px;
  height: 44px;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  border: 1px;
  border-radius: 20px;
  border: 1px solid #ffffff1f;
  color: white;
  font-size: 18px;
  background: #33303b;
  padding: 10px;
  :focus-visible {
    outline: none;
  }
`
const ButtonSearch = styled(Button)`
  width: 100px;
  height: 44px;
  color: #ffffff;
  background: #8544f5;
  box-shadow: 2px 2px 8px 16px #0000001a;
  cursor: pointer;
  :disabled {
    background-color: #3c3742 !important;
    border-color: #3c3742;
    box-shadow: none;
    color: #666171 !important;
    cursor: not-allowed;
    border-radius: 16px;
  }
`

const TopUser = () => {
  const [limit, setLimit] = useState(20)
  const [skip, setSkip] = useState(1)
  const [dataTable, setDataTable] = useState([])
  const { isMobile } = useMatchBreakpoints()
  const [indexPage, setIndexPage] = useState(0)
  const [pageNumbers, setPageNumbers] = useState([])
  const [priceUSD, setPriceUSD] = useState(0)
  const currentPage = useRef(1)
  const [inputValue, setInputValue] = useState('')
  const [message, setMessage] = useState('')
  const url = `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users?limit=${limit}&skip=${skip - 1}&address=${inputValue}`
  const getPriceMatic = async () => {
    const data = await axios.get(`${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history`)
    setPriceUSD(data.data.data.price_history[0][1])
  }
  const getData = async () => {
    const data = await axios.get(url)
    if (data.data.status === false) {
      setMessage(data.data.message)
    }
    const array = data.data.data.map((item, index) => {
      return {
        address: item.address,
        totalLock: item.totalLockV1 + item.totalLockV2,
        totalComm: item.totalCommV1 + item.totalCommV2,
        totalMaxOut: (item.totalLockV1 + item.totalLockV2) * 5,
        volumnOnTree: item.volumnV1 + item.volumnV2,
        children: item.children.length,
      }
    })
    setDataTable(array)
    for (let i = 1; i <= data.data.totalPage; i++) {
      pageNumbers.push(i)
    }
  }

  const shortenURL = (s: string, max: number) => {
    return s.length > max ? s.substring(0, max / 2 - 1) + '...' + s.substring(s.length - max / 2 + 2, s.length) : s
  }
  const handleNext = () => {
    currentPage.current += 1
    setSkip(currentPage.current + 1)
  }
  const handlePre = () => {
    currentPage.current -= 1
    setSkip(currentPage.current - 1)
  }
  useEffect(() => {
    getData()
  }, [limit, skip])

  const handleChangeIndex = (value) => {
    currentPage.current = value
    setSkip(currentPage.current)
  }
  const handleSearch = () => {
    getData()
  }
  const handleEnter = (event) => {
    setInputValue(event.target.value)
    if (event.key === 'Enter') {
      getData()
      setMessage('')
    }
  }
  useEffect(() => {
    getPriceMatic()
  }, [])
  const renderHistory = () => {
    return (
      <>
        {dataTable.length > 0 &&
          dataTable.map((items, index) => {
            return (
              <tr key={index} style={{ background: index % 2 === 0 ? 'rgba(23, 19, 32, 1)' : 'none' }}>
                <Td>
                  <TextData>
                    {currentPage.current === 1 ? index + 1 : (currentPage.current - 1) * 20 + (index + 1)}
                  </TextData>
                </Td>
                <Td>
                  <TextData>{shortenURL(`${items.address}`, 18)}</TextData>
                </Td>
                <Td>
                  <AmountData>
                    <TextData>
                      ${' '}
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={items.totalLock * priceUSD}
                        decimals={0}
                        duration={0.5}
                        separator=","
                      />
                    </TextData>
                  </AmountData>

                  {/* <AmountData>
                      <TextData>
                        ~ ${' '}
                        <CountUp
                          start={0}
                          preserveValue
                          delay={0}
                          end={(claimHistory.totalLock + claimHistory.amount) * claimHistory.rateUSD}
                          decimals={claimHistory.totalLock > 0 ? 4 : 0}
                          duration={0.5}
                          separator=","
                        />
                      </TextData>
                    </AmountData> */}
                </Td>
                <Td>
                  <AmountData>
                    <TextData>
                      ${' '}
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={items.totalComm}
                        decimals={0}
                        duration={0.5}
                        separator=","
                      />
                    </TextData>
                    /
                    <TextData>
                      ${' '}
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={items.totalMaxOut}
                        decimals={0}
                        duration={0.5}
                        separator=","
                      />
                    </TextData>
                  </AmountData>
                </Td>
                <Td>
                  <AmountData>
                    <TextData>
                      ${' '}
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={items.volumnOnTree}
                        decimals={0}
                        duration={0.5}
                        separator=","
                      />
                    </TextData>
                  </AmountData>
                </Td>
                <Td>
                  <AmountData>
                    <TextData>
                      <CountUp
                        start={0}
                        preserveValue
                        delay={0}
                        end={items.children}
                        decimals={0}
                        duration={0.5}
                        separator=","
                      />
                    </TextData>
                  </AmountData>
                </Td>
              </tr>
            )
          })}
      </>
    )
  }
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: '10px',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Title>List Users</Title>
        <div style={{ position: 'relative', display: 'flex', gap: '20px' }}>
          <InputSearch
            value={inputValue}
            onKeyDown={(e) => handleEnter(e)}
            placeholder="Search address..."
            onChange={(e) => setInputValue(e.target.value)}
          />
          {/* <div style={{position: 'absolute', top: 5, right: 10}}>
          <img src='/images/V3/search.png' width={36} height={36} />
        </div> */}
          <ButtonSearch disabled={inputValue === ''} onClick={handleSearch}>
            Search
          </ButtonSearch>
        </div>
      </div>
      {message !== '' && (
        <Text fontSize={18} textAlign={'center'} fontWeight={700} style={{ color: 'red' }}>
          {message}
        </Text>
      )}
      <StyledTableHis>
        <TablePool>
          <TableScroll>
            <table>
              <thead>
                <tr>
                  <Th>
                    <TextTh>STT</TextTh>
                  </Th>
                  <Th style={{ width: '400px' }}>
                    <TextTh>Address</TextTh>
                  </Th>
                  <Th>
                    <TextTh>TotalLock</TextTh>
                  </Th>
                  <Th>
                    <TextTh>Comm/MaxOut</TextTh>
                  </Th>
                  <Th>
                    <TextTh>Volumn</TextTh>
                  </Th>
                  <Th>
                    <TextTh>Children</TextTh>
                  </Th>
                </tr>
              </thead>
              <tbody>
                <>{renderHistory()}</>
              </tbody>
            </table>
          </TableScroll>
          {dataTable.length <= 1 ? null : (
            <StyledPage>
              <BtnPage onClick={handlePre} disabled={currentPage.current === 1}>
                <Image src={images.arrowBack} alt="" width={20} height={20} />
                Previous
              </BtnPage>
              <StyledPageNumber>
                {currentPage.current === 1 ? (
                  <>
                    <BtnPageNumber className="active">{currentPage.current}</BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current + 1)}>
                      {currentPage.current + 1}
                    </BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current + 2)}>
                      {currentPage.current + 2}
                    </BtnPageNumber>
                    <BtnPageNumber>...</BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(pageNumbers[pageNumbers.length - 1])}>
                      {pageNumbers[pageNumbers.length - 1]}
                    </BtnPageNumber>
                  </>
                ) : currentPage.current === pageNumbers[pageNumbers.length - 1] ||
                  currentPage.current === pageNumbers[pageNumbers.length - 2] ||
                  currentPage.current === pageNumbers[pageNumbers.length - 3] ? (
                  <>
                    <BtnPageNumber onClick={() => handleChangeIndex(pageNumbers[0])}>{pageNumbers[0]}</BtnPageNumber>
                    <BtnPageNumber>...</BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current - 2)}>
                      {currentPage.current - 2}
                    </BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current - 1)}>
                      {currentPage.current - 1}
                    </BtnPageNumber>
                    <BtnPageNumber className="active">{currentPage.current}</BtnPageNumber>
                  </>
                ) : (
                  <>
                    <BtnPageNumber className="active">{currentPage.current}</BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current + 1)}>
                      {currentPage.current + 1}
                    </BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(currentPage.current + 2)}>
                      {currentPage.current + 2}
                    </BtnPageNumber>
                    <BtnPageNumber>...</BtnPageNumber>
                    <BtnPageNumber onClick={() => handleChangeIndex(pageNumbers[pageNumbers.length - 1])}>
                      {pageNumbers[pageNumbers.length - 1]}
                    </BtnPageNumber>
                  </>
                )}
              </StyledPageNumber>
              <BtnPage onClick={handleNext} disabled={currentPage.current === pageNumbers[pageNumbers.length - 1]}>
                Next
                <Image src={images.arrowForward} alt="" width={20} height={20} />
              </BtnPage>
            </StyledPage>
          )}
        </TablePool>
      </StyledTableHis>
    </Wrapper>
  )
}
export default TopUser
