import { useTranslation } from '@pancakeswap/localization'
import { Box, Modal, useToast, Button, Input, Text } from '@pancakeswap/uikit'
import { useWeb3LibraryContext, useWeb3React } from '@pancakeswap/wagmi'
import CountUp from 'react-countup'
import useTheme from 'hooks/useTheme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { trendyColors } from 'style/trendyTheme'
import useConfirmTransaction from 'hooks/useConfirmTransaction'
import { useCallWithMarketGasPrice } from 'hooks/useCallWithMarketGasPrice'
import { usePoolsV1Contract, usePoolsV11Contract } from 'hooks/useContract'
import { ToastDescriptionWithTx } from 'components/Toast'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from '../../../../../packages/swap-sdk/src/constants'
import { ClaimPoolModalProps } from './type'
import { useBalance } from 'wagmi'
import contracts from '../../../../config/constants/contracts'
import { formatBigNumber } from '../../../../utils/formatBalance'
import axios from 'axios'

// STYLE
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: center;
  gap: 1em;
`
const ClaimAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledButton = styled(Button)`
  background: #1fc7d4;
`
const depositModal = {}
const depositInput = {
  borderRadius: '10px',
}
const ThreeDots = styled.p`
  @keyframes blinkdot {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.loading span {
    animation-name: blinkdot;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
  &.loading span:nth-child(2) {
    animation-delay: 0.2s;
  }
  &.loading span:nth-child(3) {
    animation-delay: 0.4s;
  }
`
const Error = styled.span`
  margin: -0.5em 0 1em;
  color: ${trendyColors.ORANGE};
`

const ClaimPoolModal: React.FC<React.PropsWithChildren<ClaimPoolModalProps>> = ({
  pool,
  // isV2,
  account,
  onDismiss,
  onSuccess,
}) => {
  const { callWithMarketGasPrice } = useCallWithMarketGasPrice()
  const [confirmedTxHash, setConfirmedTxHash] = useState('')
  const { t } = useTranslation()
  const { toastSuccess, toastError } = useToast()
  const [isValidAmount, setIsValidAmount] = useState(true)
  const [priceMaticUsd, setPriceMaticUsd] = useState(0)
  // const poolContract = usePoolsContract()
  const poolV1Contract = usePoolsV1Contract()
  const poolV11Contract = usePoolsV11Contract()
  // const poolV2Contract = usePoolsV2Contract()
  const { chainId } = useActiveWeb3React()
  // const { data, isFetched } = useBalance({
  //   addressOrName: contracts.pools[chainId],
  // })
  const { data: data1, isFetched: isFetched1 } = useBalance({
    addressOrName: contracts.poolsV1[chainId],
  })
  // const { data: data2, isFetched: isFetched2 } = useBalance({
  //   addressOrName: contracts.poolsV2[chainId],
  // })
  // const balance = isFetched && data ? Number(formatBigNumber(data.value)) : 0
  const balance1 = isFetched1 && data1 ? Number(formatBigNumber(data1.value)) : 0
  // const balance2 = isFetched2 && data2 ? Number(formatBigNumber(data2.value)) : 0
  let typeClaim = 'claimReward'
  if (pool.currentRewardV1 > pool.currentRewardV2) typeClaim = 'claimRewardV1'
  // else if(pool.currentRewardV1 < pool.currentRewardV2) typeClaim = 'claimReward'

  let ct = poolV1Contract
  // if (
  //   (typeClaim === 'claimReward' && pool.currentRewardV2 >= pool.currentRewardV1) ||
  //   (typeClaim === 'claimRewardV1' && balance2 >= pool.currentRewardV1)
  // )
  //   ct = poolV2Contract
  if (
    (typeClaim === 'claimRewardV1' && balance1 < pool.currentRewardV1) ||
    (typeClaim === 'claimReward' && pool.currentRewardV2 >= pool.currentRewardV1 && balance1 < pool.currentRewardV2)
  )
    ct = poolV11Contract
  // console.log(typeClaim, balance, pool.currentRewardV1, pool.currentRewardV2)
  // console.log(balance, pool.currentReward)
  const timeStamps = Math.floor(Date.now() / 1000)
  const getPriceMaticUsd = async () => {
    const data = await axios.get(`${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/market/history`)
    const arr = data.data.data.price_history[0][1]
    setPriceMaticUsd(arr)
  }
  const { isConfirming, handleConfirm } = useConfirmTransaction({
    onConfirm: () => {
      return callWithMarketGasPrice(ct, typeClaim, [pool.pid])
    },
    onSuccess: async ({ receipt }) => {
      setConfirmedTxHash(receipt.transactionHash)
      toastSuccess(t('Claim reward successfully !'), <ToastDescriptionWithTx txHash={receipt.transactionHash} />)
      onDismiss()
      updateClaimed()
    },
  })
  const updateClaimed = async () => {
    const postClaimed = await axios.post(
      `${process.env.NEXT_PUBLIC_DEFAULT_API_URL}/users/claim`,

      {
        account: account,
        pid: pool.pid,
        date: Math.floor(timeStamps / 86400),
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    await onSuccess()
  }
  useEffect(() => {
    getPriceMaticUsd()
  }, [])
  return (
    <Modal
      style={depositModal}
      title={''}
      onDismiss={onDismiss}
      hideCloseButton={false}
      borderRadius={25}
      // headerBackground="rgb(105 84 156 / 77%)"
      // background={'linear-gradient(139.08deg, #171718 1.7%, rgba(86, 27, 211, 0.84) 108.66%)'}
    >
      <Wrapper>
        <ClaimAmount>
          <Text fontSize="28px" textAlign="center">
            CLAIM
          </Text>
          <Text fontSize="18px">Current reward:</Text>
          <Text fontSize="16px" style={{ display: 'flex' }}>
            {
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                end={Number(pool.currentReward * priceMaticUsd)}
                decimals={pool.currentReward === 0 ? 0 : 2}
                duration={0.5}
              />
            }
            $&ensp;~&ensp;
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CountUp
                separator=","
                start={0}
                preserveValue
                delay={0}
                end={Number(pool.currentReward)}
                decimals={pool.currentReward === 0 ? 0 : 4}
                duration={0.5}
              />
              &ensp;
              <img src={`/images/chains/${chainId}.png`} alt="pool name" width={18} />
            </div>
          </Text>
        </ClaimAmount>
        <StyledButton
          variant={'danger'}
          width="180px"
          disabled={isConfirming || (!isValidAmount ? true : false)}
          onClick={handleConfirm}
        >
          {isConfirming ? (
            <ThreeDots className="loading">
              Claiming<span>.</span>
              <span>.</span>
              <span>.</span>
            </ThreeDots>
          ) : (
            'Claim'
          )}
        </StyledButton>
      </Wrapper>
    </Modal>
  )
}

export default ClaimPoolModal
